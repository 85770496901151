import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaArrowLeft, FaTimes, FaCheckCircle, FaTimesCircle } from "react-icons/fa";
const _ = require('lodash');
const backURL = process.env.REACT_APP_BACK_URL

const TrainingAnalysisPage = ({ onBack }) => {
    const [users, setUsers] = useState([]);
    const [userDetails, setUserDetails] = useState(null);
    const [moduleDetails, setModuleDetails] = useState([]);
    const [showUserModal, setShowUserModal] = useState(false);
    const [userId, setUserId] = useState(null);

    const token = localStorage.getItem('yariyan_gal');

    // API Calls
    const fetchUsers = async () => {
        const toast_module = toast.loading("Please wait...");
        const requestOptions = {
            method: 'POST',
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },

            body: JSON.stringify({ page: 1 }),
        };
        try {
            const response = await fetch(`${backURL}/trainingUser`, requestOptions);
            const result = await response.json();
            console.log('con fetch : ', result);
            setUsers(result);
            toast.update(toast_module, { render: "Success!", type: "success", isLoading: false, autoClose: 200 });
        } catch (err) {
            toast.update(toast_module, { render: "Response Failed !", type: "error", isLoading: false, autoClose: 500 });
            console.error(err);
        }
    }

    const fetchUsersDetails = async (uid) => {
        setUserId(uid);
        setModuleDetails([]);
        const toast_module = toast.loading("Please wait...");
        const requestOptions = {
            method: 'POST',
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },

            body: JSON.stringify({ userId: uid }),
        };
        try {
            const response = await fetch(`${backURL}/getTrainingUser`, requestOptions);
            const result = await response.json();
            console.log('con userdetails : ', result.data);
            setUserDetails(result.data);
            setShowUserModal(true);
            toast.update(toast_module, { render: "Success!", type: "success", isLoading: false, autoClose: 200 });
        } catch (err) {
            toast.update(toast_module, { render: "Response Failed !", type: "error", isLoading: false, autoClose: 500 });
            console.error(err);
        }
    };

    const fetchModuleDetails = async (topicId) => {
        const toast_module = toast.loading("Please wait...");
        const requestOptions = {
            method: 'POST',
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },

            body: JSON.stringify({ userId: userId, fileId: topicId }),
        };
        try {
            const response = await fetch(`${backURL}/getAllAnalysis`, requestOptions);
            const result = await response.json();
            console.log('con mod details : ', result.data);
            setModuleDetails(result.data);
            toast.update(toast_module, { render: "Success!", type: "success", isLoading: false, autoClose: 200 });
        } catch (err) {
            toast.update(toast_module, { render: "Response Failed !", type: "error", isLoading: false, autoClose: 500 });
            console.error(err);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    console.log('uu  : ', userDetails);

    return (
        <div className="trainAnalysisView">
            <ToastContainer
                position="top-left"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

            <div className="showModel">
                <button className="backButton" onClick={onBack}>
                    <FaArrowLeft style={{ marginRight: "8px" }} />
                    Back
                </button>

                <table style={modalStyles.table}>
                    <thead>
                        <tr>
                            <th style={modalStyles.th}>Name</th>
                            <th style={modalStyles.th}>Username</th>
                            <th style={modalStyles.th}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.uid}>
                                <td style={modalStyles.td}>{user.name}</td>
                                <td style={modalStyles.td}>{user.username}</td>
                                <td style={modalStyles.td}>
                                    <button
                                        style={modalStyles.button}
                                        onClick={() => fetchUsersDetails(user.uid)}
                                    >
                                        View Analysis
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Trainer Modal */}
            {showUserModal && userDetails && (
                <div className="overlay">
                    <div className="modalAnalysis">
                        <div className="closeHeader">
                            <h1 style={{ flex: "1", textAlign: "center", margin: 0 }}>User Training Details</h1>
                            <button
                                className="closeButton"
                                onClick={() => setShowUserModal(false)}
                            >
                                <FaTimes />
                            </button>
                        </div>
                        <p><strong>Modules Count:</strong> {userDetails.module_counts}</p>
                        <p><strong>Training Q/A Count:</strong> {userDetails.training_chat}</p>

                        <select
                            id="moduleSelect"
                            style={modalStyles.select}
                            onChange={(e) => fetchModuleDetails(e.target.value)}
                        >
                            <option value="">View Module</option>
                            {userDetails.module.map((module) => (
                                <option value={module.topicId} key={module.topicId}>
                                    {module.moduleName}
                                </option>
                            ))}
                        </select>

                        {moduleDetails && (
                            <div style={{ marginTop: "20px" }}>
                                <h3 style={modalStyles.subHeading}>Submodule Details</h3>
                                <table style={modalStyles.table}>
                                    <thead>
                                        <tr>
                                            <th style={modalStyles.th}>SubModule</th>
                                            <th style={modalStyles.th}>Training Time</th>
                                            <th style={modalStyles.th}>Total Steps</th>
                                            <th style={modalStyles.th}>Complete Steps</th>
                                            <th style={modalStyles.th}>Complete Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {moduleDetails.map((submodule) => (
                                            <tr key={submodule.ID}>
                                                <td style={modalStyles.td}>{submodule.subModule}</td>
                                                <td style={modalStyles.td}>{submodule.trainingTime}</td>
                                                <td style={modalStyles.td}>
                                                    {submodule.endIndex - submodule.startIndex}
                                                </td>
                                                <td style={modalStyles.td}>{submodule.Step + 1}</td>
                                                <td className="statusCheck"
                                                    style={{
                                                        color: submodule.completeStatus ? "green" : "red"
                                                    }}
                                                >
                                                    {submodule.completeStatus ? (
                                                        <>
                                                            <FaCheckCircle style={{ marginRight: "6px" }} />
                                                            100%
                                                        </>
                                                    ) : (
                                                        <>
                                                            <FaTimesCircle style={{ marginRight: "6px" }} />
                                                            {submodule.endIndex && submodule.startIndex ?
                                                                (((submodule.Step + 1) * 100) / (submodule.endIndex - submodule.startIndex)).toFixed(2)
                                                                : 'N/A'}%
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

// Styles for Modal
const modalStyles = {
    table: {
        width: "100%",
        borderCollapse: "collapse",
        marginTop: "10px",
    },
    th: {
        backgroundColor: "#f8f9fa",
        color: "#333",
        fontWeight: "bold",
        padding: "12px",
        borderBottom: "2px solid #dee2e6",
        textAlign: "left",
    },
    td: {
        padding: "5px",
        borderBottom: "1px solid #dee2e6",
        textAlign: "left",
        marginBottom: "10px"
    },
    button: {
        padding: "8px 12px",
        backgroundColor: "#007BFF",
        color: "white",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "14px",
    },
    select: {
        padding: "8px",
        borderRadius: "4px",
        border: "1px solid #ccc",
        marginTop: "15px",
        width: "100%",
    },
    heading: {
        fontSize: "20px",
        marginBottom: "15px",
        color: "#333",
    },
    subHeading: {
        fontSize: "18px",
        marginTop: "20px",
        marginBottom: "10px",
        color: "#007BFF",
    },
};

export default TrainingAnalysisPage;
