import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, useTexture, Loader, Environment, useFBX, useAnimations, OrthographicCamera } from '@react-three/drei';
import { MeshStandardMaterial } from 'three/src/materials/MeshStandardMaterial';
import { LinearEncoding, sRGBEncoding } from 'three/src/constants';
import { LineBasicMaterial, MeshPhysicalMaterial, Vector2, PlaneGeometry } from 'three';
import { createAnimation_train } from '../converter';
import blinkData from '../blendDataBlink.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './App.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FaArrowRight, FaMicrophone, FaMicrophoneSlash, FaPaperPlane, FaComments, FaUserShield, FaCheck, FaTimes } from "react-icons/fa";
import Swal from 'sweetalert2';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; // To handle tables and more
// import io from "socket.io-client"; // Import Socket.IO if not already done
import { connectSocket, getSocket } from "./Socket";
import zIndex from '@mui/material/styles/zIndex';
import { pingpong } from 'three/src/math/MathUtils.js';
// import { shouldProcessLinkClick } from 'react-router-dom/dist/dom';

const _ = require('lodash');
const backURL = process.env.REACT_APP_BACK_URL

// function Avatar({ avatar_url, speak, setAudioSource, playing, blendData, setClipActions }) {

//     let gltf = useGLTF(avatar_url);
//     let morphTargetDictionaryBody = null;
//     let morphTargetDictionaryLowerTeeth = null;
//     const BlendData = blendData.blendData
//     let filename = blendData.filename

//     const [
//         bodyDiffuseTexture,
//         eyesTexture,
//         teethTexture,
//         bodySpecularTexture,
//         bodyRoughnessTexture,
//         bodyNormalTexture,
//         teethNormalTexture,
//         // hairDiffuseTexture,
//         suitDiffuseTexture,
//         suitNormalTexture,
//         suitRoughnessTexture,
//         hairAlphaTexture,
//         hairNormalTexture,
//         hairRoughnessTexture,

//     ] = useTexture([
//         "/train/body_diffuse.jpg",
//         "/train/eyes.jpg",
//         "/train/teeth_diffuse.jpg",
//         "/train/body_specular.jpg",
//         "/train/body_roughness.jpg",
//         "/train/body_normal.jpg",
//         "/train/teeth_normal.jpg",
//         // "/train/h_diffuse.jpg",
//         "/train/suit_diffuse.jpg",
//         "/train/suit_normal.jpg",
//         "/train/suit_roughness.jpg",
//         "/train/h_alpha.jpg",
//         "/train/h_normal.jpg",
//         "/train/h_roughness.jpg",
//     ]);

//     _.each([
//         bodyDiffuseTexture,
//         eyesTexture,
//         teethTexture,
//         teethNormalTexture,
//         bodySpecularTexture,
//         bodyRoughnessTexture,
//         bodyNormalTexture,
//         // hairDiffuseTexture,
//         suitDiffuseTexture,
//         suitNormalTexture,
//         suitRoughnessTexture,
//         hairAlphaTexture,
//         hairNormalTexture,
//         hairRoughnessTexture,
//     ],
//         t => { t.encoding = sRGBEncoding; t.flipY = false; });

//     bodyNormalTexture.encoding = LinearEncoding;
//     suitNormalTexture.encoding = LinearEncoding;
//     teethNormalTexture.encoding = LinearEncoding;
//     hairNormalTexture.encoding = LinearEncoding

//     gltf.scene.traverse(node => {
//         if (node.type === 'Mesh' || node.type === 'LineSegments' || node.type === 'SkinnedMesh') {
//             node.castShadow = true;
//             node.receiveShadow = true;
//             node.frustumCulled = false;
//             if (node.name.includes("Body")) {

//                 node.castShadow = true;
//                 node.receiveShadow = true;
//                 node.material = new MeshPhysicalMaterial();
//                 node.material.map = bodyDiffuseTexture;
//                 node.material.envMapIntensity = .7;
//                 node.material.roughness = 5;
//                 node.material.roughnessMap = bodyRoughnessTexture;
//                 node.material.normalMap = bodyNormalTexture;
//                 node.material.normalScale = new Vector2(0.8, 0.8);

//                 morphTargetDictionaryBody = node.morphTargetDictionary;

//             }

//             if (node.name.includes("Eyes")) {
//                 node.material = new MeshStandardMaterial();
//                 node.material.map = eyesTexture;
//                 // node.material.shininess = 100;
//                 node.material.roughness = 0.1;
//                 node.material.envMapIntensity = 0.5;

//             }

//             if (node.name.includes("Brows")) {
//                 node.material = new LineBasicMaterial({ color: 0x000000 });
//                 node.material.linewidth = 1;
//                 node.material.opacity = 0.5;
//                 node.material.transparent = true;
//                 node.visible = false;
//             }

//             if (node.name.includes("Teeth")) {

//                 node.receiveShadow = true;
//                 node.castShadow = true;
//                 node.material = new MeshStandardMaterial();
//                 node.material.roughness = 0.1;
//                 node.material.map = teethTexture;
//                 node.material.normalMap = teethNormalTexture;

//                 node.material.envMapIntensity = 0.9;
//             }

//             if (node.name.includes("Hair")) {
//                 node.material = new MeshStandardMaterial();
//                 // node.material.map = hairDiffuseTexture;
//                 node.material.alphaMap = hairAlphaTexture;
//                 node.material.normalMap = hairNormalTexture;
//                 node.material.roughnessMap = hairRoughnessTexture;
//                 node.material.roughness = 25;

//                 node.material.transparent = true;
//                 node.material.opacity = 3;
//                 node.material.depthWrite = false;
//                 node.material.side = 0;
//                 node.material.color.setHex(0x000000);

//                 node.material.envMapIntensity = 0.6;
//             }

//             if (node.name.includes("Suit")) {
//                 node.material = new MeshStandardMaterial();

//                 node.material.map = suitDiffuseTexture;
//                 node.material.roughnessMap = suitRoughnessTexture;
//                 node.material.roughness = 4;
//                 node.material.normalMap = suitNormalTexture;
//                 // node.material.color.setHex(0xffffff);
//                 // node.material.envMapIntensity = 1;
//             }


//             if (node.name.includes("Goggel_body")) {
//                 node.material = new MeshStandardMaterial({
//                     color: 0x000000,
//                     roughness: 0.7,
//                     metalness: 0.5,
//                 });
//             }

//             if (node.name.includes("Goggel_glass")) {
//                 node.material = new MeshStandardMaterial({
//                     color: 0xffffff,
//                     transparent: true,
//                     opacity: 0.3,
//                     roughness: 0.1,
//                     metalness: 0,
//                 });
//             }


//             if (node.name.includes("TeethLower")) {
//                 morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
//             }
//         }

//     });

//     const [clips, setClips] = useState([]);
//     const mixer = useMemo(() => new THREE.AnimationMixer(gltf.scene), []);

//     useEffect(() => {

//         if (speak === false)
//             return;

//         console.log('hhhh', filename);
//         if (BlendData.length > 0) {
//             let newClips = [createAnimation_train(BlendData, morphTargetDictionaryBody, 'HG_Body'),
//             createAnimation_train(BlendData, morphTargetDictionaryLowerTeeth, 'HG_TeethLower')];

//             filename = backURL + filename;

//             setClips(newClips);
//             setAudioSource(filename);
//         } else {
//             filename = backURL + filename;
//             setAudioSource(filename);
//         }
//     }, [speak]);



//     let idleFbx = useFBX('/stand.fbx');
//     let speakFbx = useFBX('/speak.fbx'); // Load the speak animation
//     let { clips: idleClips } = useAnimations(idleFbx.animations);
//     let { clips: speakClips } = useAnimations(speakFbx.animations);

//     // Track current and previous actions for blending
//     let currentAction = useRef(null);
//     let previousAction = useRef(null);

//     useEffect(() => {
//         if (gltf.scene) {
//             // Set the initial position, rotation, and scale of the character
//             gltf.scene.position.set(-0.10, 0.8, 0);
//             gltf.scene.rotation.x = THREE.MathUtils.degToRad(-90);
//             gltf.scene.scale.set(0.6, 0.6, 0.6);

//             // Play the initial idle animation
//             let idleClipAction = mixer.clipAction(idleClips[0], gltf.scene);
//             idleClipAction.play();
//             currentAction.current = idleClipAction;

//             // Create and play the blink animation
//             let blinkClip = createAnimation_train(blinkData, morphTargetDictionaryBody, 'HG_Body');
//             let blinkAction = mixer.clipAction(blinkClip, gltf.scene);
//             blinkAction.play();
//         }
//     }, []);

//     useEffect(() => {
//         if (gltf.scene) {
//             // Determine the new animation to play
//             let newAction = speak
//                 ? mixer.clipAction(speakClips[0], gltf.scene) // Speak animation
//                 : mixer.clipAction(idleClips[0], gltf.scene); // Idle animation

//             // Blend from the previous action to the new action
//             if (currentAction.current && currentAction.current !== newAction) {
//                 previousAction.current = currentAction.current;
//                 previousAction.current.fadeOut(0.5); // Smoothly fade out the previous animation
//             }

//             newAction.reset().fadeIn(0.5).play(); // Smoothly fade in the new animation
//             currentAction.current = newAction;
//         }
//     }, [speak]);

//     // Play animation clips when available
//     useEffect(() => {
//         if (playing === false) return;

//         const actions = _.map(clips, (clip) => {
//             let clipAction = mixer.clipAction(clip);
//             clipAction.setLoop(THREE.LoopOnce);
//             clipAction.play();
//             return clipAction;
//         });

//         setClipActions(actions);
//     }, [playing]);

//     useFrame((state, delta) => {
//         mixer.update(delta);
//     });

//     return (
//         <group name="avatar">
//             <primitive object={gltf.scene} dispose={null} />
//         </group>
//     );

// }

// const STYLES = {
//     area: { position: 'absolute', bottom: '0', left: '0', zIndex: 500, display: 'flex' },
//     speak: { padding: '5px', display: 'block', color: '#FFFFFF', background: '#222222', border: 'None', right: '5px' },
//     stop: { padding: '10px', left: '10px', color: 'red', background: '#FFFFF', radious: '5px' },
//     label: { color: '#777777', fontSize: '0.5em' }
// }

function TrainingRoom() {
    const navigate = useNavigate();
    const { meetingID } = useParams(); // Get the meeting ID from the URL
    // const [blendData, setBlendData] = useState({});
    const [selectedTopic, setSelectedTopic] = useState("");
    const [prevDataSubmodule, setprevDataSubmodule] = useState("");
    const [relatedOptions, setRelatedOptions] = useState([]);
    const [analyzeImage, setAnalyzeImage] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [text, setText] = useState([]);
    const [submodule, setSubmodule] = useState('');
    const [submodule_index, setSubmodule_index] = useState();
    const [chatMessages, setChatMessages] = useState([]); // Stores chat history
    const [startIndex, setStartIndex] = useState(); // for store start index
    const [endIndex, setEndIndex] = useState(); // for store end index
    const [msg, setMsg] = useState("");
    const [isSidebarVisible, setSidebarVisible] = useState(true);
    const [isChatOpen, setIsChatOpen] = useState(false); // To toggle the chat window
    const [activeUsers, setActiveUsers] = useState([]); //for get ActiveUsers
    const [selectedOption, setSelectedOption] = useState(null);
    const [isCorrect, setIsCorrect] = useState(null);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [prevData, setPrevData] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [privtateSid, setPrivtateSid] = useState('');
    const [permissionPopup, setPermissionPopup] = useState([]);
    const [aprovePermission, setAprovePermission] = useState(true);
    const chatMessagesRef = useRef(null);
    const textRef = useRef(null);
    const [meetingDetails, setMeetingDetails] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [isSocketConnected, setIsSocketConnected] = useState(false);
    const location = useLocation();
    const pin = location.state?.pin;
    const liveUserID = location.state?.liveUserID;
    const traininguser = location.state?.name;
    const file_ID = location.state?.file_ID;
    const language = location.state?.lang;
    // const meetingUrl = location.state?.meetingUrl;

    const token = localStorage.getItem('yariyan_gal' || "");
    // const roleId = localStorage.getItem('rid' || 1);
    const uid = localStorage.getItem("uid" || "");
    // console.log('uuu  : ', uid);

    const styles = {
        notFoundContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: '#f8f9fa',
            color: '#343a40',
            textAlign: 'center',
        },
        heading: {
            fontSize: '2.5rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
        },
        message: {
            fontSize: '1.2rem',
            color: '#6c757d',
        }
    };

    useEffect(() => {
        const fetchMeetingDetails = async () => {
            try {
                const response = await fetch(`${backURL}/meetingDetails`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ meetingID, pin }),
                });
                if (!response.ok) throw new Error("Failed to fetch meeting details");

                const result = await response.json();

                if (result.status !== "failed") {
                    setMeetingDetails(result.msg);

                    // Connect socket and set isSocketConnected after connection
                    const socket = connectSocket(`${backURL}/live`, {
                        query: {
                            meetingID: meetingID,
                            pin: pin,
                            liveUserID: liveUserID,
                            traininguser: traininguser,
                        },
                    })

                    if (socket) {
                        // Handle socket connection
                        socket.on("connect", () => {
                            setIsSocketConnected(true);
                            console.log("Socket connected!");
                        });

                        // Handle incoming messages
                        socket.on("message", (message) => {
                            // console.log("Received message:", message);
                            setActiveUsers(message["ActiveUser"] || []);
                            const socketMessage = {
                                type: "answer",
                                text: message["message"],
                            };
                            setChatMessages((prevMessages) => [...prevMessages, socketMessage]);
                        });

                        // Handle disconnection
                        socket.on("disconnect", () => {
                            setIsSocketConnected(false);
                            console.warn("Socket disconnected!");
                        });
                    } else {
                        console.error("Socket connection failed!");
                    }
                } else {
                    setNotFound(true);
                }
            } catch (error) {
                console.error("Error fetching meeting details:", error);
                setNotFound(true);
            }
        };

        fetchMeetingDetails();
    }, []);


    useEffect(() => {
        fetchPreviousData(meetingID);
    }, [meetingID]);


    // function leaveMeetingRoom() {
    //     // Emit a custom event to notify the server
    //     socket.emit("leaveroom", { room: meetingID });

    //     // Disconnect the client
    //     socket.disconnect();

    //     console.log(`Disconnected from the server and left the room: ${meetingID}`);
    // }

    const autoResize = (textarea) => {
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
    };

    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    const handleLogout = async () => {
        try {
            localStorage.clear(); // Clears all session storage
            navigate("/render");
        } catch (e) {
            toast.error("Some thing went wrong!");
            // console.log("error in logout: ", e);
        }
    };


    const getTopicResponse = async (question) => {
        if (question === '') {
            toast.error("Prompt can't be empty.");
            return;
        }

        const socket = getSocket();
        if (!socket) {
            console.warn("Socket is not connected. Skipping getNextStep.");
            return;
        }
        if (uid == null || uid == '') {
            const response = await fetch(`${backURL}/updatePermission`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ meetingID, liveUserID }),
            });
            if (!response.ok) throw new Error("Failed to fetch permission details");

            const result = await response.json();
            if (result.status == "failed") {
                toast.info("You are not allowed for chat please take permission from trainer.")
                return
            }
            else {
                // console.log("permission granted approved!");
                setAprovePermission(false);
            }
        }
        try {
            toast.success("Please wait...");
            const sendData = {
                fileId: file_ID,
                language: language,
                question: question,
                meetingID: meetingID
            }
            socket.emit("getLLmResponse", sendData);
            setMsg('');
        } catch (error) {
            toast.error(error)
        }
    }

    useEffect(() => {
        if (selectedTopic !== "") {
            setRelatedOptions([]);
            fetchSubModule(selectedTopic);
            // setChatMessages([]);
            setText([]);
            setStartIndex();
            setEndIndex();
            setCurrentStepIndex(0);
        }
    }, [selectedTopic]);

    const fetchSubModule = async (selectedTopic) => {
        const toast_sub = toast.loading("Please wait...");
        const requestOptions = {
            method: 'POST',
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },

            body: JSON.stringify({ file_id: selectedTopic, language: `${language}` }),
        };
        try {
            const response = await fetch(`${backURL}/trainsubModule`, requestOptions);
            const result = await response.json();
            if (result) {
                toast.update(toast_sub, {
                    render: "Success",
                    type: "success",
                    isLoading: false,
                    autoClose: 200
                });

                setRelatedOptions(result);
            } else {
                toast.update(toast_sub, {
                    render: "Data not found",
                    type: "error",
                    isLoading: false,
                    autoClose: 500
                });
            }
        } catch (err) {
            toast.update(toast_sub, {
                render: "Some thing went wrong",
                type: "error",
                isLoading: false,
                autoClose: 500
            });
            console.error(err);
        }
    }


    const handleConfirmStep = async (confirmed) => {
        try {
            setSelectedOption(null);
            setFeedbackMessage('');
            if (confirmed) {
                const start = startIndex + 1;
                setStartIndex(start);
                getNextStep(start);

                if (startIndex < endIndex) {
                    // setCurrentStepIndex(currentStepIndex + 1); // Move to the next step
                    setShowPopup(true); // Show popup for the next step
                } else {
                    Swal.fire({
                        title: "Congratulations!",
                        text: "You have completed all steps!",
                        icon: "success",
                        confirmButtonText: "Awesome!",
                        confirmButtonColor: "#4CAF50",
                    });
                }
            } else {
                Swal.fire({
                    title: "Step Canceled",
                    text: "You have chosen to cancel this step.",
                    icon: "info",
                    confirmButtonText: "OK",
                    confirmButtonColor: "#f44336",
                });
                setStartIndex(startIndex + 1);
            }
            setShowPopup(false);
        } catch (e) {
            console.log("some thing error in handle step : ", e);
        }
    };

    // Function to handle `nextStepResponse`
    const handleNextStepResponse = (result) => {

        if (result.error) {
            toast.error(result.error);
            return
        } else {
            setCurrentStepIndex(currentStepIndex + 1);
            setText((prevText) => [...prevText, result]);
            // setText([result]);

            // Handle additional state updates
            if (result.startIndex) {
                setCurrentStepIndex(0);
                setStartIndex(result.startIndex - 1);
                setEndIndex(result.endIndex);
            }
        }
    };

    useEffect(() => {

        if (!isSocketConnected) {
            return;
        }
        const socket = getSocket();

        if (!socket) return;

        if (!socket.hasListeners('getRoomChat')) {
            socket.on('getRoomChat', (message) => {
                const socketMessage = {
                    type: "answer",
                    text: message['message'],
                };
                setChatMessages((prevMessages) => [...prevMessages, socketMessage]);
            });
        }

        socket.on('getPrivateChat', (message) => {
            const socketMessage = {
                type: "answer",
                text: message['message'],
            };
            setChatMessages((prevMessages) => [...prevMessages, socketMessage]);
        });

        if (!socket.hasListeners('getLLmResponse')) {
            socket.on('getLLmResponse', (message) => {
                const socketMessage = {
                    type: "question",
                    text: message['question'],
                };
                setChatMessages((prevMessages) => [...prevMessages, socketMessage]);

                const aiResponse = {
                    type: "answer",
                    text: message['message'],
                };
                setChatMessages((prevMessages) => [...prevMessages, aiResponse]);
            });
        }

        if (!socket.hasListeners('nextStepResponse')) {
            socket.on("nextStepResponse", handleNextStepResponse);
        }

        socket.on('getPermission', (message) => {
            // console.log("message for admin aproove : ", message)
            // setPermissionPopup(message);
            setPermissionPopup((prev) => [...prev, message]);
        });

        // Add listeners only once
        if (!socket.hasListeners("message")) {
            socket.on('message', (message) => {
                setActiveUsers(message['ActiveUser']);
                const socketMessage = {
                    type: "answer",
                    text: message['message'],
                };
                setChatMessages((prevMessages) => [...prevMessages, socketMessage]);
            });
        }

        if (!socket.hasListeners("sendFeedbackToRoom")) {
            socket.on('sendFeedbackToRoom', (message) => {
                setSelectedOption(message['option']);
                setIsCorrect(message['checkCorrect']);
                setFeedbackMessage(message['feedback']);
            });
        }

        if (!socket.hasListeners("getPermissionAction")) {
            socket.on('getPermissionAction', (message) => {
                if (message['status'] === 'success') {
                    setAprovePermission(false);
                    toast.success(message['message']);
                } else {
                    toast.error(message['message']);
                }
            });
        }


        if (!socket.hasListeners('error')) {
            socket.on('error', (message) => {
                console.log('Error get room chat :', message);
            });
        }

        if (!socket.hasListeners('disconnect')) {
            socket.on('disconnect', () => {
                console.log('Socket disconnected.');
            });
        }

        return () => {
            // Cleanup listeners when component unmounts
            if (socket) {
                socket.off("message");
                socket.off("nextStepResponse");
                socket.off('getRoomChat');
                socket.off('getPrivateChat');
                socket.off('getPermission');
                socket.off('getLLmResponse');
                socket.off('sendFeedbackToRoom');
                socket.off('getPermissionAction');
                socket.off("error");
                socket.off("connect");
                socket.off("disconnect");
            }
        };
    });

    const getNextStep = (index = null) => {
        const socket = getSocket();

        if (!socket || !isSocketConnected) {
            console.warn("Socket is not connected. Skipping getNextStep.");
            return;
        }

        const payload = {
            file_id: selectedTopic || file_ID,
            submodule: submodule || prevDataSubmodule,
            submodule_index: 1,
            index,
            language,
            meetingID,
            uid,
        };

        socket.emit("getNextStep", payload);
    };

    const getRoomChat = async (msg) => {
        const socket = getSocket();
        if (!socket) {
            console.warn("Socket is not connected. Skipping getNextStep.");
            return;
        }
        // check the permission type
        if (uid == null || uid == '') {
            const response = await fetch(`${backURL}/updatePermission`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ meetingID, liveUserID }),
            });
            if (!response.ok) throw new Error("Failed to fetch permission details");

            const result = await response.json();
            if (result.status == "failed") {
                toast.info("You are not allowed for chat please take permission from trainer.")
                return
            } else {
                // console.log("permission granted approved!");
                setAprovePermission(false);
            }
        }

        if (privtateSid != null || privtateSid != '') {
            // console.log("i am under the pprivate sid", privtateSid);
            const sendValue = {
                roomId: meetingID,
                socketId: privtateSid,
                msg: msg,
                name: traininguser
            }
            socket.emit("getPrivateChat", sendValue);
        } else {
            const sendValue = {
                roomId: meetingID,
                msg: msg,
                name: traininguser
            }
            socket.emit("getRoomChat", sendValue);
        }

        setMsg('');
    }

    const handleOpenImage = (image) => {
        try {
            setAnalyzeImage(image);
            setIsOpen(true);
        } catch (e) {
            console.log("Error occured : ", e);
        }
    };

    const handleConfirm = (userSid) => {
        // console.log("confirm sid : ", userSid);
        const socket = getSocket();
        if (!socket) {
            console.warn("Socket is not connected.");
            return;
        }
        setPermissionPopup((prev) => prev.filter((req) => req.userSid !== userSid)); // Remove the approved popup
        const sendValue = {
            roomId: meetingID,
            socketId: userSid,
            permission: 1
        }
        socket.emit("getPermissionAction", sendValue);
    };

    const handleReject = (userSid) => {
        // console.log("reject sid:  ", userSid);
        const socket = getSocket();
        if (!socket) {
            console.warn("Socket is not connected.");
            return;
        }
        setPermissionPopup((prev) => prev.filter((req) => req.userSid !== userSid)); // Remove the rejected popup
        const sendValue = {
            roomId: meetingID,
            socketId: userSid,
            permission: 0
        }
        socket.emit("getPermissionAction", sendValue);
    };

    // console.log("Permission:", permissionPopup);
    // Function to handle media steps (image or video)
    const handleMediaStep = (step) => {

        if (step.media_type === 'image') {
            return (
                <div className='steps' onClick={() => handleOpenImage(backURL + step.media_path)}>
                    {/* <img src={step.media_path} alt={step.submodule} style={{ maxWidth: '95%' }} /> */}

                    <p>{step.content}</p>
                    <img src={backURL + step.media_path} alt={step.submodule} style={{ maxWidth: '100%' }} className='media-image' />
                    {isOpen && (
                        <Lightbox
                            mainSrc={analyzeImage}
                            onCloseRequest={() => setIsOpen(false)}
                        />
                    )}
                </div>

            );
        }
    };


    const fetchPreviousData = async (meetingID) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ roomID: meetingID })
            };
            const response = await fetch(backURL + '/getTrainingData', requestOptions);

            if (!response.ok) {
                throw new Error('Failed to fetch Data');
            }

            const mess = await response.json();
            let lastTerm = (mess.length - 1);
            // console.log('last term : ', lastTerm);
            // console.log('hello previous data : ', mess);
            // console.log('hello previous data : ', mess[0].startIndex);
            if (mess[0].startIndex != null) {
                setStartIndex(lastTerm === 0 ? mess[0].startIndex - 1 : mess[lastTerm].Currentindex);
                setEndIndex(mess[0].endIndex);
                setCurrentStepIndex(mess[lastTerm].Currentindex);
            }
            setPrevData(mess);
            setprevDataSubmodule(mess[lastTerm].submodule);
        } catch (error) {
            console.error('Error fetching messages:', error);
            return;
        }
    };

    // console.log('startindex: ', startIndex);
    // console.log('endindex: ', endIndex);
    // console.log('current step index ', currentStepIndex);

    // const handleAnswer = (step, option) => {
    //     setSelectedOption(option);
    //     const isAnswerCorrect = option === step.correct_answer; // Assuming `step.correctAnswer` holds the correct answer
    //     setIsCorrect(isAnswerCorrect);
    //     setFeedbackMessage(isAnswerCorrect ? step.feedback.correct : step.feedback.incorrect);
    // };

    const handleAnswer = (step, option) => {
        // setSelectedOption(option);
        const isAnswerCorrect = option === step.correct_answer; // Assuming `step.correct_answer` holds the correct answer
        // setIsCorrect(isAnswerCorrect);
        // setFeedbackMessage(isAnswerCorrect ? step.feedback.correct : step.feedback.incorrect);

        if (uid) {
            // Send feedback to all rooms using Socket.IO
            const feedbackData = {
                meetingID: meetingID, // Assuming `step.id` is the step identifier
                selectedOption: option,
                isCorrect: isAnswerCorrect,
                feedback: isAnswerCorrect ? step.feedback.correct : step.feedback.incorrect,
            };

            const socket = getSocket(); // Replace with your socket instance
            if (socket) {
                socket.emit('sendFeedbackToRoom', feedbackData);
            }
        }
    };


    const handleRelatedOptionChange = async (value) => {
        const [subModule, submoduleIndex] = value.split(',');
        setSubmodule(subModule);
        setSubmodule_index(submoduleIndex);
        setText([]);
        setStartIndex();
        setEndIndex();
        setCurrentStepIndex(0);
    };

    useEffect(() => {
        if (submodule) {
            getNextStep(); // Call the function whenever `submodule` changes
        }
    }, [submodule]); // Runs whenever `submodule` state changes

    useEffect(() => {
        if (chatMessagesRef.current) {
            chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
        }
    }, [chatMessages]);


    useEffect(() => {
        if (textRef.current) {
            textRef.current.scrollTo({
                top: textRef.current.scrollHeight,
                behavior: "smooth",
            });
        }
    }, [text]);

    // console.log("text : ", text);
    // console.log("currentStepIndex : ", currentStepIndex);
    // console.log("start : ", startIndex);
    // console.log("end : ", endIndex);

    if (notFound) {
        return (
            <div style={styles.notFoundContainer}>
                <h1 style={styles.heading}>404 - Meeting Not Found</h1>
                <p style={styles.message}>
                    The meeting you're looking for doesn't exist or may have been deleted.
                </p>
            </div>
        );
    }

    if (!meetingDetails) {
        return <div>Loading...</div>; // Show a loading spinner or message while fetching data
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            // getTopicResponse(msg);
        }
    };

    const filterUsers = (e) => {
        const input = e.target.value;
        if (input.includes("@")) {
            const filtered = activeUsers.filter((user) => user.userType === 0 || user.userType === 1);
            setFilteredUsers(filtered);
            setShowSuggestions(true); // Show suggestions when @ is typed
        } else {
            setFilteredUsers([]);
            setShowSuggestions(false); // Hide suggestions if @ is not typed
        }
    };

    const handleUserClick = (socketID) => {
        // console.log(`Selected User's socketID: ${socketID}`);
        setPrivtateSid(socketID);
        setShowSuggestions(false); // Hide suggestions after selecting a use
        setMsg('');
    };

    const handletopicSelection = async () => {
        setSelectedTopic(meetingDetails['fileID'] || null);
    }

    const getPermission = () => {
        const socket = getSocket();
        if (!socket) {
            console.warn("Socket is not connected. Skipping getNextStep.");
            return;
        }
        const sendPermissionValue = {
            roomId: meetingID,
            name: traininguser
        }
        socket.emit("getPermission", sendPermissionValue);
    }

    // console.log('activeUsers: ', activeUsers);
    // console.log('aprovePermission : ', aprovePermission);.

    return (
        <div className="full">
            <ToastContainer
                position="top-left"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <div className={`${isSidebarVisible ? 'w-64' : 'w-16'} bg-gray-800 text-white transition-all duration-300 ease-in-out`}>
                <div className="p-4 flex justify-between items-center">
                    <button onClick={toggleSidebar} className="text-white">
                        <FontAwesomeIcon icon={isSidebarVisible ? faTimes : faBars} />
                    </button>

                    {isSidebarVisible && (
                        <div className="flex items-center">
                            <button onClick={handleLogout} className="text-sm flex items-center">
                                <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                                Logout
                            </button>
                        </div>

                    )}
                </div>
                <div className='active_users'>
                    <h1>Active Users: <span >{activeUsers.length}</span></h1>
                    {activeUsers.length > 0 && (
                        <div className='showActiveUsers'>
                            {activeUsers.map((user, index) => (
                                <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                    {/* Green Circle */}
                                    <div
                                        style={{
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "green",
                                            marginRight: "8px",
                                        }}
                                    ></div>
                                    {/* User Name */}
                                    <p style={{ margin: 0 }}>
                                        {user.name} <span className='adminShow'>{user.userType === 1 ? "Admin" : ""}</span>
                                    </p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div>
                    {/* Button to open the chat window */}
                    <button
                        className="chat-open-btn"
                        onClick={() => setIsChatOpen(!isChatOpen)}
                    >
                        Ask Question
                        <FaComments size={10} color="#007bff" /> {/* Chat icon */}
                    </button>
                </div>
            </div>
            {/* Main content */}
            <div className="flex-1 flex-col">
                <div className="train-div">
                    <div className="meetingContainer">
                        <div className="showMeetingName">
                            <h1>Topic : {meetingDetails['trainingName']}</h1>
                        </div>
                        <div>
                            {uid != null && uid !== '' && (
                                <button className="trainingStart" onClick={handletopicSelection}>
                                    Start Training
                                </button>
                            )}
                        </div>
                    </div>
                    <div>
                        {/* Second Dropdown */}
                        {relatedOptions.length > 0 && (
                            <div className="related-dropdown">
                                <select
                                    id="related-select"
                                    className="dropdown"
                                    value={`${submodule},${submodule_index}`} // Ensure it matches the option value format
                                    onChange={(e) => handleRelatedOptionChange(e.target.value)}
                                >
                                    <option value="">Select Sub Module</option>
                                    <option value="all,1">Complete Module</option>
                                    {relatedOptions.map((option, index) => (
                                        <option key={index} value={`${option.submodule},${option.submodule_index}`}>
                                            {option.submodule}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                    </div>
                    {/* Display topic description */}
                    <div className="topic-details" ref={textRef}>
                        {/* for previous data */}
                        {prevData.map((step, index) => (
                            <div key={index} className="step-container">
                                {step.submodule === 'Purpose' && (
                                    <div className="desc">
                                        Description : {step.content}
                                        {/* <b>Total Steps: {Math.max(0, endIndex - startIndex)}</b> */}
                                    </div>
                                )}
                                {step.step === 'media' && handleMediaStep(step)}

                                {step.step === 'instruction' && step.submodule !== 'Purpose' && (
                                    <div className="steps">
                                        <p>Step : {step.content}</p>
                                    </div>
                                )}
                            </div>
                        ))}

                        {/* for real time data */}
                        {text.map((step, index) => (
                            <div key={index} className="step-container">
                                {step.submodule === 'Purpose' && (
                                    <div className="desc">
                                        Description : {step.content}
                                        <b>Total Steps: {Math.max(0, endIndex - startIndex)}</b>
                                    </div>
                                )}
                                {step.step === 'media' && handleMediaStep(step)}

                                {step.step === 'instruction' && step.submodule !== 'Purpose' && (
                                    <div className="steps">
                                        <p>Step : {step.content}</p>
                                    </div>
                                )}

                                {step.step === 'question' && (
                                    <div className="quiz-popup-content_room">
                                        <div className="quiz-question">{step.content}</div>
                                        <div className="quiz-options">
                                            {step.options.map((option, index) => (
                                                <button
                                                    key={index}
                                                    className={`quiz-option ${selectedOption === option
                                                        ? isCorrect
                                                            ? 'correct-option'
                                                            : 'incorrect-option'
                                                        : ''
                                                        }`}
                                                    onClick={() => {
                                                        if (uid) {
                                                            handleAnswer(step, option);
                                                        }
                                                    }}
                                                    disabled={selectedOption !== null}
                                                >
                                                    {option}
                                                </button>
                                            ))}
                                        </div>
                                        {feedbackMessage && (
                                            <div className="quiz-feedback">
                                                <div>{feedbackMessage}</div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    {uid != null && uid !== '' && showPopup && (
                        <div className="popup-overlay">
                            <div className="popup-content">
                                <p className="popup-question">Do you want to proceed with Step {currentStepIndex + 1}?</p>
                                <div className="popup-buttons">
                                    <button className="popup-button cancel" onClick={() => handleConfirmStep(false)}>No</button>
                                    <button className="popup-button confirm" onClick={() => handleConfirmStep(true)}>Yes</button>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Navigation Icons */}
                    <div className="navigation-buttons">
                        {startIndex > 0 && startIndex <= endIndex && uid != null && uid != '' && (
                            <FaArrowRight
                                className="nav-icon next"
                                onClick={() => {
                                    if (startIndex === endIndex) {
                                        handleConfirmStep(true);
                                    } else {
                                        setShowPopup(true);
                                    }
                                }}
                            />
                        )}
                    </div>

                    {/* Input Message Box Below Navigation Buttons */}
                    {isChatOpen && (
                        <>
                            <div className="chat-messages" ref={chatMessagesRef}>
                                {chatMessages
                                    .map((message, messageIndex) => (
                                        <div
                                            key={messageIndex}
                                            className={`chat-train-message ${message.type === "question" ? "user-messages" : "ai-message"
                                                }`}
                                        >
                                            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                                {message.text}
                                            </ReactMarkdown>
                                        </div>
                                    ))}
                            </div>
                            <div className="chat-box-fixed">
                                {/* Text Area */}
                                <textarea
                                    className="text-area"
                                    value={msg}
                                    onChange={(e) => {
                                        setMsg(e.target.value);
                                        autoResize(e.target);
                                        filterUsers(e); // Filter users on every key press
                                    }}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Say Hello!"
                                />

                                {/* Display Active Users when @ is typed */}
                                {showSuggestions && filteredUsers.length > 0 && (
                                    <div className="user-suggestions">
                                        {filteredUsers.map((user) => (
                                            <div
                                                key={user.socketID}
                                                className="user-item"
                                                onClick={() => handleUserClick(user.socketID)}
                                            >
                                                <div className="user-name">{user.name} <span className='adminShow'>{user.userType === 1 ? "Admin" : ""}</span></div>
                                                {/* <div className="user-type">{user.userType === 0 ? "Active" : "Inactive"}</div> */}
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {/* for ask permission */}
                                {uid === null && aprovePermission === true && (
                                    <button className='permission' onClick={getPermission}>
                                        <FaUserShield />
                                    </button>
                                )}

                                {/* Send Button */}
                                <button
                                    className="msgbtn"
                                    id="send"
                                    onClick={() => getRoomChat(msg)}
                                >
                                    <FaPaperPlane size={20} color="#007bff" />
                                </button>

                                <button
                                    className="msgbtn"
                                    id="send"
                                    onClick={() => getTopicResponse(msg)}
                                >
                                    <span className="AI">AI</span>
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {permissionPopup.length > 0 && (
                permissionPopup.map((popup, index) => (
                    <div className='container_permission' key={index}>
                        <p className='message_permission'>
                            <strong>{popup.message}</strong> is requesting permission to chat.
                        </p>
                        <div className='actions_permission'>
                            <button className='confirmButton' onClick={() => handleConfirm(popup.userSid)}>
                                <FaCheck style={{ marginRight: "5px" }} />
                                Confirm
                            </button>
                            <button className='rejectButton' onClick={() => handleReject(popup.userSid)}>
                                <FaTimes style={{ marginRight: "5px" }} />
                                Reject
                            </button>
                        </div>
                    </div>
                ))
            )}
            {/* <ReactAudioPlayer
                src={audioSource}
                ref={audioPlayer}
                onEnded={playerEnded}
                onCanPlayThrough={playerReady}
            /> */}

            <Canvas
                dpr={2}
                onCreated={(ctx) => {
                    ctx.gl.physicallyCorrectLights = true;
                }}
            >
                <OrthographicCamera makeDefault zoom={1300} position={[0, 1.65, 1]} />
                <Suspense fallback={null}>
                    <Environment background={false} files="/images/photo_studio_loft_hall_1k.hdr" />
                </Suspense>
                <Suspense fallback={null}>
                    <Bg />
                </Suspense>
                <Suspense fallback={null}>
                    {/* <Avatar
                        avatar_url="/rohit.glb"
                        speak={speak}
                        setSpeak={setSpeak}
                        setAudioSource={setAudioSource}
                        playing={playing}
                        blendData={blendData}
                        setClipActions={setClipActions}
                    /> */}
                </Suspense>
            </Canvas>
            <Loader dataInterpolation={(p) => `Loading... please wait`} />
        </div >

    );
}

function Bg() {

    const texture = useTexture('/images/training.png');

    return (
        <mesh position={[0, 1.8, -1]} scale={[1.2, 0.9, 1]}>
            <planeGeometry args={[]} />
            <meshBasicMaterial map={texture} />

        </mesh>
    )
}

export default TrainingRoom;