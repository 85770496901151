import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const backURL = process.env.REACT_APP_BACK_URL

const CreateRoom = () => {
    const [username, setUsername] = useState('');
    const navigate = useNavigate();

    const createRoom = async () => {
        if (username.trim()) {
            const requestOptions = {
                method: 'POST',
                headers:
                {
                    'Content-Type': 'application/json',
                },

                body: JSON.stringify({ username: username }),
            };
            const response = await fetch(`${backURL}/create_room`, requestOptions);
            const roomData = await response.json();
            navigate(roomData['room_url'])
            console.log(roomData);

        } else {
            alert("Please enter your name before creating a room.");
        }
    };

    return (
        <div>
            <h1>Create a Room</h1>
            <input
                type="text"
                placeholder="Enter training name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <button onClick={createRoom}>Create Room</button>
        </div>
    );
};

export default CreateRoom;
