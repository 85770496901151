import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, useTexture, Loader, Environment, useFBX, useAnimations, OrthographicCamera } from '@react-three/drei';
import { MeshStandardMaterial } from 'three/src/materials/MeshStandardMaterial';
import { LinearEncoding, sRGBEncoding } from 'three/src/constants';
import { LineBasicMaterial, MeshPhysicalMaterial, Vector2, PlaneGeometry } from 'three';
import { createAnimation_train } from '../converter';
import blinkData from '../blendDataBlink.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './App.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FaArrowRight, FaMicrophone, FaMicrophoneSlash, FaPaperPlane, FaComments } from "react-icons/fa";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Swal from 'sweetalert2';
import QuizComponent from './QuizComponent';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; // To handle tables and more
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField } from '@mui/material';

const _ = require('lodash');
const backURL = process.env.REACT_APP_BACK_URL

// function Avatar({ avatar_url, speak, setAudioSource, playing, blendData, setClipActions }) {

//     let gltf = useGLTF(avatar_url);
//     let morphTargetDictionaryBody = null;
//     let morphTargetDictionaryLowerTeeth = null;
//     const BlendData = blendData.blendData
//     let filename = blendData.filename

//     const [
//         bodyDiffuseTexture,
//         eyesTexture,
//         teethTexture,
//         bodySpecularTexture,
//         bodyRoughnessTexture,
//         bodyNormalTexture,
//         teethNormalTexture,
//         // hairDiffuseTexture,
//         suitDiffuseTexture,
//         suitNormalTexture,
//         suitRoughnessTexture,
//         hairAlphaTexture,
//         hairNormalTexture,
//         hairRoughnessTexture,

//     ] = useTexture([
//         "/train/body_diffuse.jpg",
//         "/train/eyes.jpg",
//         "/train/teeth_diffuse.jpg",
//         "/train/body_specular.jpg",
//         "/train/body_roughness.jpg",
//         "/train/body_normal.jpg",
//         "/train/teeth_normal.jpg",
//         // "/train/h_diffuse.jpg",
//         "/train/suit_diffuse.jpg",
//         "/train/suit_normal.jpg",
//         "/train/suit_roughness.jpg",
//         "/train/h_alpha.jpg",
//         "/train/h_normal.jpg",
//         "/train/h_roughness.jpg",
//     ]);

//     _.each([
//         bodyDiffuseTexture,
//         eyesTexture,
//         teethTexture,
//         teethNormalTexture,
//         bodySpecularTexture,
//         bodyRoughnessTexture,
//         bodyNormalTexture,
//         // hairDiffuseTexture,
//         suitDiffuseTexture,
//         suitNormalTexture,
//         suitRoughnessTexture,
//         hairAlphaTexture,
//         hairNormalTexture,
//         hairRoughnessTexture,
//     ],
//         t => { t.encoding = sRGBEncoding; t.flipY = false; });

//     bodyNormalTexture.encoding = LinearEncoding;
//     suitNormalTexture.encoding = LinearEncoding;
//     teethNormalTexture.encoding = LinearEncoding;
//     hairNormalTexture.encoding = LinearEncoding

//     gltf.scene.traverse(node => {
//         if (node.type === 'Mesh' || node.type === 'LineSegments' || node.type === 'SkinnedMesh') {
//             node.castShadow = true;
//             node.receiveShadow = true;
//             node.frustumCulled = false;
//             if (node.name.includes("Body")) {

//                 node.castShadow = true;
//                 node.receiveShadow = true;
//                 node.material = new MeshPhysicalMaterial();
//                 node.material.map = bodyDiffuseTexture;
//                 node.material.envMapIntensity = .7;
//                 node.material.roughness = 5;
//                 node.material.roughnessMap = bodyRoughnessTexture;
//                 node.material.normalMap = bodyNormalTexture;
//                 node.material.normalScale = new Vector2(0.8, 0.8);

//                 morphTargetDictionaryBody = node.morphTargetDictionary;

//             }

//             if (node.name.includes("Eyes")) {
//                 node.material = new MeshStandardMaterial();
//                 node.material.map = eyesTexture;
//                 // node.material.shininess = 100;
//                 node.material.roughness = 0.1;
//                 node.material.envMapIntensity = 0.5;

//             }

//             if (node.name.includes("Brows")) {
//                 node.material = new LineBasicMaterial({ color: 0x000000 });
//                 node.material.linewidth = 1;
//                 node.material.opacity = 0.5;
//                 node.material.transparent = true;
//                 node.visible = false;
//             }

//             if (node.name.includes("Teeth")) {

//                 node.receiveShadow = true;
//                 node.castShadow = true;
//                 node.material = new MeshStandardMaterial();
//                 node.material.roughness = 0.1;
//                 node.material.map = teethTexture;
//                 node.material.normalMap = teethNormalTexture;

//                 node.material.envMapIntensity = 0.9;
//             }

//             if (node.name.includes("Hair")) {
//                 node.material = new MeshStandardMaterial();
//                 // node.material.map = hairDiffuseTexture;
//                 node.material.alphaMap = hairAlphaTexture;
//                 node.material.normalMap = hairNormalTexture;
//                 node.material.roughnessMap = hairRoughnessTexture;
//                 node.material.roughness = 25;

//                 node.material.transparent = true;
//                 node.material.opacity = 3;
//                 node.material.depthWrite = false;
//                 node.material.side = 0;
//                 node.material.color.setHex(0x000000);

//                 node.material.envMapIntensity = 0.6;
//             }

//             if (node.name.includes("Suit")) {
//                 node.material = new MeshStandardMaterial();

//                 node.material.map = suitDiffuseTexture;
//                 node.material.roughnessMap = suitRoughnessTexture;
//                 node.material.roughness = 4;
//                 node.material.normalMap = suitNormalTexture;
//                 // node.material.color.setHex(0xffffff);
//                 // node.material.envMapIntensity = 1;
//             }


//             if (node.name.includes("Goggel_body")) {
//                 node.material = new MeshStandardMaterial({
//                     color: 0x000000,
//                     roughness: 0.7,
//                     metalness: 0.5,
//                 });
//             }

//             if (node.name.includes("Goggel_glass")) {
//                 node.material = new MeshStandardMaterial({
//                     color: 0xffffff,
//                     transparent: true,
//                     opacity: 0.3,
//                     roughness: 0.1,
//                     metalness: 0,
//                 });
//             }


//             if (node.name.includes("TeethLower")) {
//                 morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
//             }
//         }

//     });

//     const [clips, setClips] = useState([]);
//     const mixer = useMemo(() => new THREE.AnimationMixer(gltf.scene), []);

//     useEffect(() => {

//         if (speak === false)
//             return;

//         console.log('hhhh', filename);
//         if (BlendData.length > 0) {
//             let newClips = [createAnimation_train(BlendData, morphTargetDictionaryBody, 'HG_Body'),
//             createAnimation_train(BlendData, morphTargetDictionaryLowerTeeth, 'HG_TeethLower')];

//             filename = backURL + filename;

//             setClips(newClips);
//             setAudioSource(filename);
//         } else {
//             filename = backURL + filename;
//             setAudioSource(filename);
//         }
//     }, [speak]);



//     let idleFbx = useFBX('/stand.fbx');
//     let speakFbx = useFBX('/speak.fbx'); // Load the speak animation
//     let { clips: idleClips } = useAnimations(idleFbx.animations);
//     let { clips: speakClips } = useAnimations(speakFbx.animations);

//     // Track current and previous actions for blending
//     let currentAction = useRef(null);
//     let previousAction = useRef(null);

//     useEffect(() => {
//         if (gltf.scene) {
//             // Set the initial position, rotation, and scale of the character
//             gltf.scene.position.set(-0.10, 0.8, 0);
//             gltf.scene.rotation.x = THREE.MathUtils.degToRad(-90);
//             gltf.scene.scale.set(0.6, 0.6, 0.6);

//             // Play the initial idle animation
//             let idleClipAction = mixer.clipAction(idleClips[0], gltf.scene);
//             idleClipAction.play();
//             currentAction.current = idleClipAction;

//             // Create and play the blink animation
//             let blinkClip = createAnimation_train(blinkData, morphTargetDictionaryBody, 'HG_Body');
//             let blinkAction = mixer.clipAction(blinkClip, gltf.scene);
//             blinkAction.play();
//         }
//     }, []);

//     useEffect(() => {
//         if (gltf.scene) {
//             // Determine the new animation to play
//             let newAction = speak
//                 ? mixer.clipAction(speakClips[0], gltf.scene) // Speak animation
//                 : mixer.clipAction(idleClips[0], gltf.scene); // Idle animation

//             // Blend from the previous action to the new action
//             if (currentAction.current && currentAction.current !== newAction) {
//                 previousAction.current = currentAction.current;
//                 previousAction.current.fadeOut(0.5); // Smoothly fade out the previous animation
//             }

//             newAction.reset().fadeIn(0.5).play(); // Smoothly fade in the new animation
//             currentAction.current = newAction;
//         }
//     }, [speak]);

//     // Play animation clips when available
//     useEffect(() => {
//         if (playing === false) return;

//         const actions = _.map(clips, (clip) => {
//             let clipAction = mixer.clipAction(clip);
//             clipAction.setLoop(THREE.LoopOnce);
//             clipAction.play();
//             return clipAction;
//         });

//         setClipActions(actions);
//     }, [playing]);

//     useFrame((state, delta) => {
//         mixer.update(delta);
//     });

//     return (
//         <group name="avatar">
//             <primitive object={gltf.scene} dispose={null} />
//         </group>
//     );

// }

// const STYLES = {
//     area: { position: 'absolute', bottom: '0', left: '0', zIndex: 500, display: 'flex' },
//     speak: { padding: '5px', display: 'block', color: '#FFFFFF', background: '#222222', border: 'None', right: '5px' },
//     stop: { padding: '10px', left: '10px', color: 'red', background: '#FFFFF', radious: '5px' },
//     label: { color: '#777777', fontSize: '0.5em' }
// }

function LiveTrain() {
    const navigate = useNavigate();
    const [selectedTopic, setSelectedTopic] = useState("");
    const [topics, setTopics] = useState([]);
    const [isSidebarVisible, setSidebarVisible] = useState(true);
    const [isTrainingFormOpen, setIsTrainingFormOpen] = useState(false);
    const [trainingTopic, setTrainingTopic] = useState('');
    const [scheduleTime, setScheduleTime] = useState('');
    const [customInvitation, setCustomInvitation] = useState('');
    const [showRoomUser, setShowRoomUser] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isCustomUserAdding, setIsCustomUserAdding] = useState(false);
    const [showSchedule, setShowSchedule] = useState([]);
    const [error, setError] = useState("");
    const [language, setLanguage] = useState('en');
    const [languages, setLanguages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [invitationText, setInvitationText] = useState('');
    const [meetingDetails, setMeetingDetails] = useState({});

    const token = localStorage.getItem('yariyan_gal' || "");

    const handleCreateRoom = async (e) => {
        e.preventDefault();
        console.log({ trainingTopic, scheduleTime, invitations: selectedUsers, topic: selectedTopic, language: language });

        try {
            const requestOptions = {
                method: 'POST',
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },

                body: JSON.stringify({ scheduleName: trainingTopic, scheduleTime: scheduleTime, moduleId: selectedTopic, language: language }),
            };
            try {
                const response = await fetch(`${backURL}/saveTrainingSchedule`, requestOptions);
                const result = await response.json();
                console.log("get result : ", result)
                if (result['status'] !== 'failed') {
                    const meetingId = result.meetingID;
                    const pin = result.pin;
                    const time = result.Time
                    const topic = result.TopicName
                    // alert(`You can open the meeting with the URL: http://192.168.0.184:3000/ ${meetingId} and the PIN: ${pin} on scheduletime :${time} for 
                    // // this topic : ${topic}`);
                    setMeetingDetails({ meetingId, pin, time, topic });

                    const invitation = `You can open the meeting with the URL: ${backURL}/${meetingId}\nPIN: ${pin}\nScheduled Time: ${time}\nTopic: ${topic}`;
                    setInvitationText(invitation);
                    setIsModalOpen(true);
                    // // toast.success("Data Save Successfully");
                    // setBlendData(result);
                } else {
                    toast.error("something went wrong");
                    console.log("sound if off");
                }
            } catch (error) {
                toast.error("Error generating response")
                console.error('Error generating response:', error);
            }
        } catch (e) {
            console.log("Something else : ", e);
        }

        setIsTrainingFormOpen(false);
    };

    const handleCopyText = () => {
        try {
            console.log("Text to copy:", invitationText);
            navigator.clipboard.writeText(invitationText).then(() => {
                toast.success("Invitation text copied!");
            }).catch(error => {
                console.error("Clipboard write failed:", error);
                toast.error("Failed to copy text.");
            });
        } catch (error) {
            console.error("Unexpected error:", error);
            toast.error("Something went wrong.");
        }
    };

    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    // fetch the train topic data
    const fetchTrain = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    language: `${language}`
                }),
            };
            try {
                const response = await fetch(`${backURL}/trainData`, requestOptions);
                const result = await response.json();
                console.log("get train data : ", result.response);
                if (result) {
                    console.log('result h bhai .')
                    setTopics(result.response);
                }
            } catch (error) {
                toast.error("Something went wrong");
                console.error('Error generating response:', error);
            }
        } catch (e) {
            console.log("Something else : ", e);
        }
    };

    const getRoomSchedule = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            };
            try {
                const response = await fetch(`${backURL}/getTrainingSchedule`, requestOptions);
                const result = await response.json();
                console.log("get schedule data : ", result);
                if (result) {
                    setShowSchedule(result)
                }
            } catch (error) {
                toast.error("Something went wrong");
                console.error('Error generating response:', error);
            }

        } catch (e) {
            console.log("Something else : ", e);
        }
    };

    const handleSelectSchedule = async (e) => {
        e.preventDefault();
        console.log("hi select time ", e.target.value);
        navigate('/meeting/' + e.target.value);
    }

    const handleLogout = async () => {
        try {
            localStorage.clear(); // Clears all session storage
            navigate("/render");
        } catch (e) {
            toast.error("Some thing went wrong!");
            console.log("error in logout: ", e);
        }
    };

    // // Fetch module from an API
    useEffect(() => {
        fetchTrain();
        fetchLanguages();
        getRoomSchedule();

    }, []);

    const fetchLanguages = async () => {
        try {
            const response = await fetch(backURL + "/languageGet"); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error("Failed to fetch languages");
            }
            const data = await response.json();
            setLanguages(data.msg); // Assume data is an array of language objects
            console.log('languages : ', data.msg);
        } catch (error) {
            console.error("Error fetching languages:", error);
        }
    };

    const handleSelect = (user) => {
        console.log("user selected : ", user);
        // Check if the user is already in the selected list
        const isAlreadySelected = selectedUsers.some(
            (selectedUser) => selectedUser.username === user.username
        );

        if (!isAlreadySelected) {
            setSelectedUsers([...selectedUsers, user]);
        }
    };

    const handleRoomForm = async (e) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    page: 1
                }),
            };
            try {
                const response = await fetch(`${backURL}/getRoomUser`, requestOptions);
                const result = await response.json();
                console.log("get trainuser data : ", result);
                if (result) {
                    console.log("hihello");
                    setShowRoomUser(result);
                }
            } catch (error) {
                toast.error("Something went wrong");
                console.error('Error generating response:', error);
            }

        } catch (e) {
            console.log("Something else : ", e);
        }
        setIsTrainingFormOpen(true);
    }

    // Handle adding a custom user
    const handleAddCustomUser = () => {
        if (customInvitation.trim() !== "") {
            // Add the custom email to the selected users list
            const newUser = { id: Date.now(), username: customInvitation };
            console.log("custom  : ", newUser)
            setSelectedUsers([...selectedUsers, newUser]);
            setCustomInvitation(""); // Clear the input field
            setIsCustomUserAdding(false); // Hide the custom input field
        }
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];  // Get the uploaded file
        if (file) {
            // Handle the file upload (e.g., send it to an API or process it locally)
            console.log("Uploaded file:", file);
        }
    };

    const handleScheduleTimeChange = (e) => {
        const selectedTime = new Date(e.target.value);
        const currentTime = new Date();

        // Check if the selected time is in the past
        if (selectedTime < currentTime) {
            setError("Schedule time cannot be in the past.");
            setScheduleTime(""); // Reset the value
        } else {
            setError(""); // Clear the error if valid
            setScheduleTime(e.target.value);
        }
    };

    return (
        <div className="full">
            <ToastContainer
                position="top-left"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <div className={`${isSidebarVisible ? 'w-64' : 'w-16'} bg-gray-800 text-white transition-all duration-300 ease-in-out`}>
                <div className="p-4 flex justify-between items-center">
                    <button onClick={toggleSidebar} className="text-white">
                        <FontAwesomeIcon icon={isSidebarVisible ? faTimes : faBars} />
                    </button>

                    {isSidebarVisible && (
                        <div className="flex items-center">
                            <button onClick={handleLogout} className="text-sm flex items-center">
                                <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                                Logout
                            </button>
                        </div>

                    )}
                </div>

                {isSidebarVisible && (
                    <div className='p-4 flex justify-between items-center'>
                        <button
                            onClick={() => handleRoomForm()}
                            className="text-sm flex items-center"
                        >
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            Create Training Room
                        </button>
                    </div>
                )}

                {isSidebarVisible && (
                    <div className="p-4">
                        <select
                            // id="language"
                            // name="language"
                            className="mb-4 w-full p-2 bg-gray-700 text-white rounded"
                            value={showSchedule} // Bind the value here
                            onChange={handleSelectSchedule} // Use onChange for better form handling
                        >
                            <option value="">Show Schedule</option> {/* Placeholder option */}
                            {showSchedule.length > 0 &&
                                showSchedule.map((schedule, index) => (
                                    <option key={index} value={schedule.url}>
                                        {schedule.trainingName}
                                    </option>
                                ))}
                        </select>
                    </div>
                )}
            </div>
            {/* Modal Popup */}
            <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Meeting Invitation</DialogTitle>
                <DialogContent>
                    <Typography variant="h6">Meeting Details</Typography>
                    <Typography variant="body1" style={{ marginBottom: '10px' }}>
                        <strong>URL:</strong> <a href={`${backURL}/${meetingDetails.meetingId}`} target="_blank" rel="noopener noreferrer">
                            {backURL}/{meetingDetails.meetingId}
                        </a>
                    </Typography>
                    <Typography variant="body1"><strong>PIN:</strong> {meetingDetails.pin}</Typography>
                    <Typography variant="body1"><strong>Scheduled Time:</strong> {meetingDetails.time}</Typography>
                    <Typography variant="body1"><strong>Topic:</strong> {meetingDetails.topic}</Typography>
                    <TextField
                        value={invitationText}
                        readOnly
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleCopyText}>Copy Invitation</Button>
                    <Button variant="outlined" onClick={() => setIsModalOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>

            {isTrainingFormOpen && (
                <div className="showTrainingForm bg-opacity-50 flex justify-left">
                    <div className="bg-white p-4 rounded-md shadow-md">
                        <h2 className="text-xl font-semibold mb-2">Create Training Room</h2>
                        <form onSubmit={handleCreateRoom}>
                            <div className="mb-3">
                                <label className="block text-sm font-medium mb-2">Training Topic :</label>
                                <input
                                    type="text"
                                    className="w-full border border-gray-300 rounded px-3 py-2"
                                    placeholder="Enter Meeting Heading"
                                    value={trainingTopic}
                                    onChange={(e) => setTrainingTopic(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3 relative">
                                <select
                                    id="topic-select"
                                    value={selectedTopic || ""}
                                    onChange={(e) => setSelectedTopic(e.target.value)}
                                    className="dropdown"
                                    required
                                >
                                    <option value="" disabled>
                                        Select a topic
                                    </option>
                                    {topics &&
                                        topics.map((topic, index) => (
                                            <option key={index} value={topic.fileId}>
                                                {topic.moduleName}
                                            </option>
                                        ))}
                                </select>
                                <div className="absolute top-full right-0 mt-0 flex items-center">
                                    <label htmlFor="file-upload" className="flex items-center cursor-pointer">
                                        {/* <UploadFileIcon className="text-blue-600 mr-1" /> */}
                                        <span className="text-blue-600 underline">create a new</span>
                                    </label>
                                    <input
                                        id="file-upload"
                                        type="file"
                                        onChange={(e) => handleFileUpload(e)}
                                        className="hidden"
                                    />
                                </div>
                            </div>
                            <div className="mt-6 mb-3 w-full">
                                <select
                                    id="topic-select"
                                    className="dropdown w-full"
                                    value={language} // Bind the value here
                                    onChange={(e) => setLanguage(e.target.value)} // Use onChange for better form handling
                                >
                                    <option value="">Select Language</option> {/* Placeholder option */}
                                    {languages &&
                                        languages.map((lang) => (
                                            <option key={lang.lang_code} value={lang.lang_code}>
                                                {lang.language}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="block text-sm font-medium mb-2">Schedule Time:</label>
                                <input
                                    type="datetime-local"
                                    className="w-full border border-gray-300 rounded px-3 py-1"
                                    required
                                    value={scheduleTime}
                                    onChange={handleScheduleTimeChange}
                                    min={new Date().toISOString().slice(0, 16)} // Prevent selecting past date/time in the picker
                                />
                                {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
                            </div>

                            <div className="mb-3">
                                <label className="block text-sm font-medium mb-1">Invite Participants :</label>

                                <input
                                    type="text"
                                    className="w-full border border-gray-300 rounded px-3 py-1 mb-1"
                                    placeholder="Search users..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />

                                <div className="showTrainRoom border border-gray-300 rounded px-3 py-1 mb-1">
                                    {showRoomUser
                                        .filter((user) =>
                                            user.username.toLowerCase().includes(searchTerm.toLowerCase())
                                        )
                                        .map((user) => (
                                            <div
                                                key={user.uid}
                                                className="flex justify-between items-center py-1"
                                            >
                                                <span>{user.name}</span>
                                                <button
                                                    type="button"
                                                    onClick={() => handleSelect(user)}
                                                    className="text-blue-500 text-sm"
                                                >
                                                    Invite
                                                </button>
                                            </div>
                                        ))}
                                </div>

                                {isCustomUserAdding ? (
                                    <div className="mb-1">
                                        <input
                                            type="email"
                                            className="w-full border border-gray-300 rounded px-3 py-2 mb-2"
                                            placeholder="Enter email"
                                            value={customInvitation}
                                            onChange={(e) => setCustomInvitation(e.target.value)}
                                        />
                                        <button
                                            type="button"
                                            onClick={handleAddCustomUser}
                                            className="bg-blue-500 text-white px-3 py-2 rounded"
                                        >
                                            Add
                                        </button>
                                    </div>
                                ) : (
                                    <button
                                        type="button"
                                        onClick={() => setIsCustomUserAdding(true)}
                                        className="bg-gray-200 text-gray-700 px-3 py-2 rounded"
                                    >
                                        Add Custom User
                                    </button>
                                )}

                                {selectedUsers.length > 0 && (
                                    <div className="mt-2">
                                        <h3 className="text-sm font-medium mb-1">Selected Participants:</h3>
                                        <div className="showInviteUser">
                                            <ul className="flex space-x-4">
                                                {selectedUsers.map((user, index) => (
                                                    <li key={index} className="py-1">
                                                        {user.username}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    onClick={() => setIsTrainingFormOpen(false)}
                                    className="bg-red-500 text-white mr-4 px-3 rounded"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-500 text-white px-4 py-2 rounded"
                                >
                                    Create
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}


            {/* <ReactAudioPlayer
                src={audioSource}
                ref={audioPlayer}
                onEnded={playerEnded}
                onCanPlayThrough={playerReady}
            /> */}

            <Canvas
                dpr={2}
                onCreated={(ctx) => {
                    ctx.gl.physicallyCorrectLights = true;
                }}
            >
                <OrthographicCamera makeDefault zoom={1300} position={[0, 1.65, 1]} />
                <Suspense fallback={null}>
                    <Environment background={false} files="/images/photo_studio_loft_hall_1k.hdr" />
                </Suspense>
                <Suspense fallback={null}>
                    <Bg />
                </Suspense>
                <Suspense fallback={null}>
                    {/* <Avatar
                        avatar_url="/rohit.glb"
                        speak={speak}
                        setSpeak={setSpeak}
                        setAudioSource={setAudioSource}
                        playing={playing}
                        blendData={blendData}
                        setClipActions={setClipActions}
                    /> */}
                </Suspense>
            </Canvas>
            <Loader dataInterpolation={(p) => `Loading... please wait`} />
        </div>
    );
}

function Bg() {

    const texture = useTexture('/images/training.png');

    return (
        <mesh position={[0, 1.8, -1]} scale={[1.2, 0.9, 1]}>
            <planeGeometry args={[]} />
            <meshBasicMaterial map={texture} />

        </mesh>
    )
}
export default LiveTrain;