import React, { useState } from 'react';

const QuizComponent = ({ step, onCloseQuiz }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [isCorrect, setIsCorrect] = useState(null);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    const handleAnswer = (option) => {
        setSelectedOption(option);
        const isAnswerCorrect = option === step.correct_answer; // Assuming `step.correctAnswer` holds the correct answer
        setIsCorrect(isAnswerCorrect);
        setFeedbackMessage(isAnswerCorrect ? step.feedback.correct : step.feedback.incorrect);
    };

    return (
        <>
            {step.step === 'question' && (
                <>
                    <div className="quiz-popup-overlay">
                        <div className="quiz-popup-content">
                            <div className="close-answer">
                                <h2 className="quiz-question-title">Question</h2>
                                {selectedOption && (
                                    <button
                                        className="close-popup-icon"
                                        onClick={onCloseQuiz} // Call the parent handler to close the popup
                                    >
                                        &times; {/* This renders an "X" icon */}
                                    </button>
                                )}
                            </div>
                            <div className="quiz-question">{step.content}</div>
                            <div className="quiz-options">
                                {step.options.map((option, index) => (
                                    <button
                                        key={index}
                                        className={`quiz-option ${selectedOption === option
                                            ? isCorrect
                                                ? 'correct-option'
                                                : 'incorrect-option'
                                            : ''
                                            }`}
                                        onClick={() => handleAnswer(option)}
                                        disabled={selectedOption !== null} // Disable after selecting an option
                                    >
                                        {option}
                                    </button>
                                ))}
                            </div>

                            {feedbackMessage && (
                                <div className="quiz-feedback">
                                    <div>{feedbackMessage}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default QuizComponent;
