import React from 'react';
import './App.css'; // Main CSS file
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

// const _ = require('lodash');
// const backURL = process.env.REACT_APP_BACK_URL

const HomePage = () => {
    const navigate = useNavigate();

    const checkTrain = () => {
        try {
            localStorage.setItem("subpurpose", 'train');
            navigate('/train');
        }
        catch (e) {
            toast.error('Some thing went wrong!');
        }
    }

    const checkQuestion = () => {
        try {
            localStorage.setItem("subpurpose", 'chat');
            navigate('/chat');
        }
        catch (e) {
            toast.error('Some thing went wrong!');
        }

    }

    const checkAnalysis = () => {
        try {
            localStorage.setItem("subpurpose", 'doc_upload');
            navigate('/doc_upload');
        }
        catch (e) {
            toast.error('Some thing went wrong!');
        }
    }

    const checkImage = () => {
        try {
            localStorage.setItem("subpurpose", 'img_analysis');
            navigate('/img_analysis');
        } catch (e) {
            toast.error('Some thing went wrong!');
        }
    }

    const Advance_tool = () => {
        try {
            localStorage.setItem("subpurpose", 'advance_tools');
            navigate('/advance_tools');
        } catch (e) {
            toast.error('Some thing went wrong!');
        }
    }

    return (
        <div className="app">
            {/* Header with Sign Up and Login */}
            <header className="header">

                <div className="logo">
                    <img src="/images/vaani_logo.jpg" alt=" " className="logo-img" />
                    <div>Vaani AI</div>
                </div>
            </header>

            {/* Main content */}
            <main className="main-content">
                <div className="main-text">Welcome to the AI platform for the Industrial Learning</div>
                <div className="card-container">
                    <div className="card" onClick={checkTrain}>
                        <img src="/images/icons/training1.png" alt="Training site" className="card-image" />

                        <h3>Training site</h3>
                        <p className="sub-heading">🚀 "Your Personal Safety Trainer Awaits!"</p>
                        <p className="paragraph-text">Embark on a guided learning adventure where safety topics come to life. Explore, learn, and master the skills that power the safety procedure. Let’s dive into the topics of your choice—together!</p>
                        <button className="know-more-button">
                            Know More
                        </button>
                    </div>
                    <div className="card" onClick={checkQuestion}>
                        <img src="/images/icons/qna.png" alt="Training site" className="card-image" />

                        <h3>Question Answer</h3>
                        <p className="sub-heading">💡 "Ask Away, I’m Your Industrial Safety Expert!"</p>
                        <p className="paragraph-text">Curious about a concept? Confused about a process? I’m here with answers! Ask questions on any topic and fuel your knowledge with accurate, easy-to-understand insights.</p>
                        <button className="know-more-button">
                            Know More
                        </button>
                        {/* <p>Select...</p> */}
                    </div>
                    {/* <div className="card" onClick={checkAnalysis}> */}
                    {/* <h3>Document Analysis</h3>
                        <p>I'm the document analyzer .I will help you for give answer of any document related question and csv/xls file analysis with generate a graph</p>
                        <p>Reply...</p>
                    </div> */}
                    <div className="card" onClick={checkImage}>
                        <img src="/images/icons/imageAnalysis.png" alt="Training site" className="card-image" />

                        <h3>Safety Work Zone Analysis</h3>
                        <p className="sub-heading">🛠️ "Safety First, Always!"</p>
                        <p className="paragraph-text">Before you begin work, let’s ensure your zone is 100% safe. Get quick, precise safety checks tailored to your work environment. Stay safe. Work smart.</p>
                        <button className="know-more-button">
                            Know More
                        </button>
                    </div>
                    {/* <div className="card" onClick={Advance_tool}>
                        <h2 className="blink">Advance Pro tool</h2>
                        <p>This is a advance pro tool for anlyze data from database,pdf,Excel,Website etc.</p>
                        <p>Reply...</p>
                    </div> */}
                </div>
            </main>

            <footer className="footer">
                <div className='footer-heading'>{'\u00A9'} Arcturus Business Solutions</div>
            </footer>

        </div>
    );
};

export default HomePage;
