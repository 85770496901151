import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, useTexture, Loader, Environment, useFBX, useAnimations, OrthographicCamera } from '@react-three/drei';
import { MeshStandardMaterial } from 'three/src/materials/MeshStandardMaterial';
import { LinearEncoding, sRGBEncoding } from 'three';
import { LineBasicMaterial, MeshPhysicalMaterial, Vector2 } from 'three';
import ReactAudioPlayer from 'react-audio-player';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import createAnimation from '../converter';
import blinkData from '../blendDataBlink.json';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { v4 as uuidv4 } from 'uuid';
import './App.css';
import * as THREE from 'three';
import axios from 'axios';
import { json, useNavigate } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; // To handle tables and more
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars, faTimes, faPlus, faPlay, faPause, faCheck, faVolumeMute, faVolumeUp, faHome } from '@fortawesome/free-solid-svg-icons';
import { FaMicrophone, FaMicrophoneSlash, FaPaperPlane } from "react-icons/fa";

import 'react-image-lightbox/style.css'; // Import the Lightbox styles
// import { FaClipboard, FaClipboardCheck } from "react-icons/fa"; // For icons
import { faCopy } from "@fortawesome/free-regular-svg-icons"; // Copy icon from regular style
// import { faCheck } from "@fortawesome/free-solid-svg-icons";
// import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";

const _ = require('lodash');

const backURL = process.env.REACT_APP_BACK_URL

function Avatar({ avatar_url, speak, setAudioSource, playing, blendData, setClipActions }) {

  let gltf = useGLTF(avatar_url);
  let morphTargetDictionaryBody = null;
  let morphTargetDictionaryLowerTeeth = null;
  const BlendData = blendData.blendData
  let filename = blendData.filename

  const [bodyTexture, eyesTexture, teethTexture, bodySpecularTexture, bodyRoughnessTexture, bodyNormalTexture, teethNormalTexture, hairTexture, tshirtDiffuseTexture, tshirtNormalTexture, tshirtRoughnessTexture, hairAlphaTexture, hairNormalTexture, hairRoughnessTexture

  ] = useTexture(["/images/body.webp", "/images/eyes.webp", "/images/teeth_diffuse.webp", "/images/body_specular.webp", "/images/body_roughness.webp", "/images/body_normal.webp", "/images/teeth_normal.webp", "/images/h_color.webp",
    "/images/tshirt_diffuse.webp", "/images/tshirt_normal.webp", "/images/tshirt_roughness.webp", "/images/h_alpha.webp", "/images/h_normal.webp", "/images/h_roughness.webp",]);

  _.each([bodyTexture, eyesTexture, teethTexture, teethNormalTexture, bodySpecularTexture, bodyRoughnessTexture, bodyNormalTexture, tshirtDiffuseTexture, tshirtNormalTexture,
    tshirtRoughnessTexture, hairAlphaTexture, hairNormalTexture, hairRoughnessTexture], t => { t.encoding = sRGBEncoding; t.flipY = false; });

  bodyNormalTexture.encoding = LinearEncoding;
  tshirtNormalTexture.encoding = LinearEncoding;
  teethNormalTexture.encoding = LinearEncoding;
  hairNormalTexture.encoding = LinearEncoding;

  gltf.scene.traverse(node => {
    if (node.type === 'Mesh' || node.type === 'LineSegments' || node.type === 'SkinnedMesh') {
      node.castShadow = true;
      node.receiveShadow = true;
      node.frustumCulled = false;

      if (node.name.includes("Body")) {

        node.castShadow = true;
        node.receiveShadow = true;

        node.material = new MeshPhysicalMaterial();
        node.material.map = bodyTexture;
        node.material.roughness = 1.7;
        node.material.roughnessMap = bodyRoughnessTexture;
        node.material.normalMap = bodyNormalTexture;
        node.material.normalScale = new Vector2(0.6, 0.6);

        morphTargetDictionaryBody = node.morphTargetDictionary;

        node.material.envMapIntensity = 0.8;

      }

      if (node.name.includes("Eyes")) {
        node.material = new MeshStandardMaterial();
        node.material.map = eyesTexture;
        // node.material.shininess = 100;
        node.material.roughness = 0.1;
        node.material.envMapIntensity = 0.5;

      }

      if (node.name.includes("Brows")) {
        node.material = new LineBasicMaterial({ color: 0x000000 });
        node.material.linewidth = 1;
        node.material.opacity = 0.5;
        node.material.transparent = true;
        node.visible = false;
      }

      if (node.name.includes("Teeth")) {

        node.receiveShadow = true;
        node.castShadow = true;
        node.material = new MeshStandardMaterial();
        node.material.roughness = 0.1;
        node.material.map = teethTexture;
        node.material.normalMap = teethNormalTexture;

        node.material.envMapIntensity = 0.9;
      }

      if (node.name.includes("Hair")) {
        node.material = new MeshStandardMaterial();
        node.material.map = hairTexture;
        node.material.alphaMap = hairAlphaTexture;
        node.material.normalMap = hairNormalTexture;
        node.material.roughnessMap = hairRoughnessTexture;

        node.material.transparent = true;
        node.material.depthWrite = false;
        node.material.side = 2;
        node.material.color.setHex(0x000000);

        node.material.envMapIntensity = 0.6;
      }

      if (node.name.includes("TSHIRT")) {
        node.material = new MeshStandardMaterial();

        node.material.map = tshirtDiffuseTexture;
        node.material.roughnessMap = tshirtRoughnessTexture;
        node.material.normalMap = tshirtNormalTexture;
        node.material.color.setHex(0xffffff);
        node.material.envMapIntensity = 0.6;
      }

      if (node.name.includes("TeethLower")) {
        morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
      }
    }

  });

  const [clips, setClips] = useState([]);
  const mixer = useMemo(() => new THREE.AnimationMixer(gltf.scene), []);

  useEffect(() => {

    if (speak === false)
      return;

    console.log('hhhh', filename);
    if (BlendData != {}) {
      let newClips = [createAnimation(BlendData, morphTargetDictionaryBody, 'HG_Body'),
      createAnimation(BlendData, morphTargetDictionaryLowerTeeth, 'HG_TeethLower')];

      filename = backURL + filename;

      setClips(newClips);
      setAudioSource(filename);
      // setSpeak(false)
    }
  }, [speak]);

  let idleFbx = useFBX('/idle.fbx');
  let { clips: idleClips } = useAnimations(idleFbx.animations);

  idleClips[0].tracks = _.filter(idleClips[0].tracks, track => {
    return track.name.includes("Head") || track.name.includes("Neck") || track.name.includes("Spine2");
  });

  idleClips[0].tracks = _.map(idleClips[0].tracks, track => {
    if (track.name.includes("Head")) {
      track.name = "head.quaternion";
    }

    if (track.name.includes("Neck")) {
      track.name = "neck.quaternion";
    }

    if (track.name.includes("Spine")) {
      track.name = "spine2.quaternion";
    }
    return track;

  });

  useEffect(() => {
    let idleClipAction = mixer.clipAction(idleClips[0]);
    idleClipAction.play();

    let blinkClip = createAnimation(blinkData, morphTargetDictionaryBody, 'HG_Body');
    let blinkAction = mixer.clipAction(blinkClip);
    blinkAction.play();
  }, []);


  // Play animation clips when available
  useEffect(() => {
    if (playing === false)
      return;

    const actions = _.map(clips, clip => {
      let clipAction = mixer.clipAction(clip);
      clipAction.setLoop(THREE.LoopOnce);
      clipAction.play();
      return clipAction
    });

    setClipActions(actions)
  }, [playing]);


  useFrame((state, delta) => {
    mixer.update(delta);
  });

  return (
    <group name="avatar">
      <primitive object={gltf.scene} dispose={null} />
    </group>
  );
}

const STYLES = {
  area: { position: 'absolute', bottom: '0', left: '0', zIndex: 500, display: 'flex' },
  speak: { padding: '5px', display: 'block', color: '#FFFFFF', background: '#222222', border: 'None', right: '5px' },
  stop: { padding: '10px', left: '10px', color: 'red', background: '#FFFFF', radious: '5px' },
  label: { color: '#777777', fontSize: '0.5em' }
}

function Chat() {
  const navigate = useNavigate();
  const [chats, setChats] = useState([]);
  const [quest, setQuest] = useState([]);
  const [text, setText] = useState("");
  const [msg, setMsg] = useState("");
  const [load, setLoad] = useState(false);
  const [blendData, setBlendData] = useState({});
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [chatHistory, setChatHistory] = useState([]);
  const [messages, setMessages] = useState([]);
  const [chatId, setChatId] = useState();
  const [clipActions, setClipActions] = useState([]);
  const [playingId, setPlayingId] = useState(null); // To track which audio is playing
  const [isListening, setIsListening] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [soundOn, setSoundOn] = useState(true);
  const [welcome, setWelcome] = useState('');
  const [subscriptions, setSubscriptions] = useState([]);

  const token = localStorage.getItem('yariyan_gal');
  const name = localStorage.getItem("name" || "");
  const username = localStorage.getItem("username" || "");
  // const language = localStorage.getItem('lang');
  const [language, setLanguage] = useState(localStorage.getItem("lang") || "");
  const subscription = localStorage.getItem('subpurpose');


  useEffect(() => {
    const initialize = async () => {
      if (token && subscription != null) {

        if (subscription === 'chat') {
          try {
            await Promise.all([
              fetchLanguages(),
              getWelcomeMessage(),
              getSUbcriptions(),
              fetchChatHistoryByUserId()
            ]);
          } catch (error) {
            console.error("Error during initialization:", error);
          }
        } else {
          navigate('/' + subscription);
        }
      } else {
        console.log("Hello guys, something is missing!");
        navigate("/");
      }
    };

    initialize();
  }, [token, subscription]);


  const [languages, setLanguages] = useState([]);

  // Fetch languages from an API
  const fetchLanguages = async () => {
    try {
      const response = await fetch(backURL + "/languageGet"); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error("Failed to fetch languages");
      }
      const data = await response.json();
      setLanguages(data.msg); // Assume data is an array of language objects
      console.log('languages : ', data.msg);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  const getSUbcriptions = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Add the token in the Authorization header
        },
      };
      const response = await fetch(backURL + "/getSubscription", requestOptions); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error("Failed to fetch subscriptions.");
      }
      const data = await response.json();
      console.log('subscriptions : ', data.response);
      setSubscriptions(data.response);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  }


  const getWelcomeMessage = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Add the token in the Authorization header
        },
        body: JSON.stringify({ text: "Hello! I am Roshni, I am here to assist you with solve your queries.", language: language, sound: soundOn })
      };
      const response = await fetch(backURL + '/chatWelcome', requestOptions);

      if (!response.ok) {
        throw new Error('Failed to fetch messages');
      }
      const data = await response.json();
      console.log("data : ", data)
      if (Array.isArray(data)) {
        setWelcome(data[0]);
        setBlendData(data[1])
        setSpeak(true);
      } else {
        setWelcome(data);
      }
    } catch (error) {
      console.error("Error fetching message:", error);
    }
  };

  const bhashini = {
    "bn": "ai4bharat/conformer-multilingual-indo_aryan-gpu--t4",
    "en": "ai4bharat/whisper-medium-en--gpu--t4",
    "gu": "ai4bharat/conformer-multilingual-indo_aryan-gpu--t4",
    "hi": "ai4bharat/conformer-hi-gpu--t4",
    "kn": "ai4bharat/conformer-multilingual-dravidian-gpu--t4",
    "ml": "ai4bharat/conformer-multilingual-dravidian-gpu--t4",
    "mr": "ai4bharat/conformer-multilingual-indo_aryan-gpu--t4",
    "or": "ai4bharat/conformer-multilingual-indo_aryan-gpu--t4",
    "pa": "ai4bharat/conformer-multilingual-indo_aryan-gpu--t4",
    "sa": "ai4bharat/conformer-multilingual-indo_aryan-gpu--t4",
    "ta": "ai4bharat/conformer-multilingual-dravidian-gpu--t4",
    "te": "ai4bharat/conformer-multilingual-dravidian-gpu--t4",
    "ur": "ai4bharat/conformer-multilingual-indo_aryan-gpu--t4"
  }

  const getResposnse = async (msg) => {
    if (msg === '') {
      toast.error("Prompt can't be empty.[In some browsers mic may not work]");
      return;
    }

    if (load === true || speak === true) {
      toast.error("Already generating response!");
      return;
    }
    setChats([]);
    setQuest([{ msg: msg, who: 'me' }]);

    setMsg("");
    setLoad(true);

    let localChatId = chatId;
    if (!chatId) {
      localChatId = uuidv4();
      console.log('cc : ', localChatId);
      setChatId(localChatId);
    }
    console.log("file nahi h generate call hua");
    const toastgen = toast.loading("Please wait...");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ prompt: `${msg}`, cid: localChatId, language: `${language}`, subscription: `${subscription}`, sound: soundOn })
    };

    // const start = new Date();
    try {
      const response = await fetch(backURL + '/generate', requestOptions);
      const result = await response.json();
      // const timeTaken = (new Date()) - start;
      // console.log('dcjskdc : ',response);
      console.log('resss: ', result);
      if (Array.isArray(result)) {
        setText(result[0].response);
        setBlendData(result[1])
        setSpeak(true);
      } else {
        console.log('hi else part call');
        setText(result['response']);
        setChats([{ msg: result['response'], who: 'bot' }]);
        setChatHistory([]);
        fetchChatHistoryByUserId();
        fetchMessagesByChatOrUserId(chatId);
      }

      toast.update(toastgen, { render: "Success!", type: "success", isLoading: false, autoClose: 500 });
      // setexct(timeTaken / 1000);
    } catch (error) {
      console.error('Error generating response:', error);
      toast.update(toastgen, { render: "Failed to generate response!", type: "error", isLoading: false, autoClose: 2000 });
      // toast.error("Failed to generate response.");
    }
    setLoad(false);
  }

  useEffect(() => {
    document.querySelector('.chat-box').scrollTop = document.querySelector('.chat-box').scrollHeight;
  }, [chats])

  const audioPlayer = useRef();

  const [speak, setSpeak] = useState(false);
  const [audioSource, setAudioSource] = useState(null);
  const [playing, setPlaying] = useState(false);

  // End of play
  function playerEnded(e) {
    try {
      setAudioSource(null);
      setSpeak(false);
      setPlaying(false);
      setChatHistory([]);
      fetchChatHistoryByUserId();
      fetchMessagesByChatOrUserId(chatId);
    } catch (error) {
      console.log("Error occured : ", e);
    }
  }

  // Player is read
  // function playerReady(e) {
  //   try {
  //     console.log('hello playerReady function call.')
  //     audioPlayer.current.audioEl.current.play();
  //     setPlaying(true);
  //     setChats([{ msg: text, who: 'bot', exct: exct }]);
  //   } catch (e) {
  //     console.log("Something else : ", e);
  //   }
  // }

  function playerReady() {
    try {
      const playPromise = audioPlayer.current.audioEl.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            setPlaying(true);
            setChats([{ msg: text, who: 'bot' }]);
            if (browserSupportsSpeechRecognition) {
              SpeechRecognition.stopListening();  // Stop listening while audio is playing
            }
          })
          .catch((error) => {
            console.log("Audio play was prevented. User hasn't interacted yet.", error);
            alert("Please interact with the page (e.g., click a button) to allow audio playback.");
          });
      }
    } catch (e) {
      console.log("Something else : ", e);
    }
  }

  // useEffect(() => {
  //   // Fetch chat history when userId is available
  //   if (userId) {
  //     setChatHistory([]);
  //     fetchChatHistoryByUserId();
  //   }
  // }, [userId]);


  const [page, setPage] = useState(1);
  const [endPage, setEndPage] = useState();


  const fetchChatHistoryByUserId = async () => {
    try {
      console.log("Fetching page :", page);
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Add the token in the Authorization header
        },
        body: JSON.stringify({ subscription: `${subscription}`, page: page })
      };
      const response = await fetch(backURL + '/latestChat', requestOptions);

      if (!response.ok) {
        throw new Error('Failed to fetch chat history');
      }

      const data = await response.json();
      console.log('all chat with uid : ', data);
      if (data) {
        setChatHistory((prevChatHistory) => [...prevChatHistory, ...data.top_cids]);
        setEndPage(data.total_pages);
      }

    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };

  const [msgPage, setMsgPage] = useState(1);

  // API function to fetch messages by chat ID or user ID
  const fetchMessagesByChatOrUserId = async (cid) => {
    try {
      setQuest([]);
      setChats([]);
      setChatId(cid);
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ chat_id: cid, page: msgPage })
      };
      const response = await fetch(backURL + '/fetchmessages', requestOptions);

      if (!response.ok) {
        throw new Error('Failed to fetch messages');
      }

      const mess = await response.json();
      console.log('hello : ', mess.messages);
      setMessages(mess.messages);
    } catch (error) {
      console.error('Error fetching messages:', error);
      return [];
    }
  };

  const toggleSound = () => {
    setSoundOn(!soundOn);
  };


  const odiyaTranslate = async (audioBlob) => {
    console.log("Calling Bhashini API...");
    console.log("bhashini code: ", bhashini[language]);
    const audioBase64 = await convertBlobToBase64(audioBlob);
    convertToMp3(audioBase64);
    const url = "https://dhruva-api.bhashini.gov.in/services/inference/pipeline"; // Bhashini API endpoint
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': '6MPVGIKTkGYAgvfLCEu5DQ3xTzSS_iGK6A7BSNSYl8fkquoVTspInRSPC6bofxe2',
    };

    const payload = JSON.stringify({
      "pipelineTasks": [
        {
          "taskType": "asr",
          "config": {
            "language": {
              "sourceLanguage": language // Odia language code
            },
            "serviceId": bhashini[language],
            "audioFormat": "webm", // Change to webm format
            "samplingRate": 16000
          }
        }
      ],
      "inputData": {
        "audio": [
          {
            "audioContent": audioBase64 // Base64 encoded audio
          }
        ]
      }
    });

    try {
      const response = await axios.post(url, payload, { headers });
      if (response.data) {
        console.log("bhasini ended : ")
        const translatedText = response.data.pipelineResponse[0].output[0].source;
        console.log("ttt : ", translatedText)
        setMsg(translatedText);
      }
    } catch (error) {
      console.error('Error translating audio:', error.response ? error.response.data : error);
    }
  };

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Return only the base64 part
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const convertToMp3 = async (base64String) => {
    try {
      // Convert the Base64 string to a Blob
      const audioBlob = base64ToBlob(base64String, 'audio/mp3');
      // console.log('aaa : ', audioBlob);

      // Ensure the audioBlob is a valid Blob object
      if (!(audioBlob instanceof Blob)) {
        throw new Error("The conversion did not produce a valid Blob.");
      }

      const randomFileName = `audio_${Math.random().toString(20).substr(2, 9)}.mp3`;
      console.log('Random File Name: ', randomFileName);
      // Prepare form data for the backend
      const formData = new FormData();
      formData.append('file', audioBlob, randomFileName);  // Append the audio file with the random name
      formData.append('name', name || 'anonymous');
      formData.append('email', username || 'unknown@example.com');
      formData.append('language', language || 'en');

      // Send the FormData to the backend
      fetch(`${backURL}/asrBackup`, {
        method: 'POST',
        body: formData,
      });
    } catch (error) {
      console.error('Error during conversion:', error);
      throw error;
    }
  };

  function base64ToBlob(base64, mimeType) {
    // Clean the base64 string to remove the data URL part (if present)
    const base64Cleaned = base64.replace(/^data:.+;base64,/, '');
    const byteCharacters = atob(base64Cleaned);  // Decode the Base64 string into bytes
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArrays.push(new Uint8Array(byteNumbers));
    }
    return new Blob(byteArrays, { type: mimeType });
  }


  const { transcript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const silenceTimeoutRef = useRef(null); // To keep track of the timeout


  const startListening = async () => {
    console.log("hello languag is  : ", language);
    audioChunksRef.current = [];

    // Request access to the microphone
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);

      mediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        await odiyaTranslate(audioBlob);
      };

      mediaRecorder.start();
      mediaRecorderRef.current = mediaRecorder;
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopListening = () => {
    if (mediaRecorderRef.current) {
      setIsListening(false);
      console.log("stop call hua");
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
    }
    clearTimeout(silenceTimeoutRef.current);
    //   console.log("other stop call hua");
    //   SpeechRecognition.stopListening();
    // }
  };

  useEffect(() => {
    if (isListening) {
      startListening();
      silenceTimeoutRef.current = setTimeout(() => {
        console.log("Auto-stopping after 15 seconds of inactivity.");
        stopListening(); // Automatically stop listening after 15 seconds
      }, 15000);
    } else {
      stopListening();
    }
  }, [isListening]);

  // const handleNavigation = async (e) => {
  //   e.preventDefault();
  //   setPlayingId(null);
  //   localStorage.setItem("subpurpose", e.target.value);
  //   navigate('/' + e.target.value);
  // }

  const handleLogout = async () => {
    try {
      setPlayingId(null);
      localStorage.clear(); // Clears all session storage
      navigate("/render");

    } catch (error) {
      toast.error("Some thing went wrong");
      console.log("error in logout : ", error)
    }
  };

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };


  const speakStop = async () => {
    try {

      console.log('Stop button clicked');

      audioPlayer.current.audioEl.current.pause();
      setPlaying(false);
      setAudioSource(null);
      setSpeak(false);
      if (browserSupportsSpeechRecognition && SpeechRecognition.getRecognition()) {
        SpeechRecognition.stopListening();
      }
      clipActions.forEach(action => {
        action.stop();  // Stop each action
      });

      setClipActions([]);  // Clear the actions
      fetchChatHistoryByUserId();
      fetchMessagesByChatOrUserId(chatId);
    } catch (e) {
      console.log("Error occured :", e);
    }
  };

  function autoResize(textarea) {
    try {
      textarea.style.height = 'auto'; // Reset the height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set it to the scroll height
    } catch (e) {
      console.log("Error occured : ", e);
    }
  }

  console.log('chats : ', chats);
  console.log('skcvv : ', chatHistory)

  const [dropdownVisible, setDropdownVisible] = useState(null);

  // Toggle dropdown visibility
  const toggleDropdown = (cid) => {
    setDropdownVisible(dropdownVisible === cid ? null : cid);
  };

  const handleLangChange = async (e) => {
    e.preventDefault();
    setLanguage(e.target.value);
    localStorage.setItem("lang", e.target.value);
  }

  useEffect(() => {
    // Sync state with localStorage on component mount
    const storedLang = localStorage.getItem("lang");
    if (storedLang) {
      setLanguage(storedLang);
    }
  }, []); // Runs only once

  const handleChatAction = (action, chatId) => {
    try {

      if (action === "delete") {
        // Call your delete chat function
        deleteChat(chatId);
        // console.log("delete chat")
      }
      // } else if (action === "rename") {
      //   // Prompt for a new name and call your rename chat function
      //   const newName = prompt("Enter new chat name:");
      //   if (newName) {
      //     // renameChat(chatId, newName);
      //     console.log('rename chat');
      //   }
      // }
      // Hide the dropdown after action
      setDropdownVisible(null);
    } catch (e) {
      console.log("Error occured : ", e);
    }
  };


  const deleteChat = async (cid) => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Add the token in the Authorization header
        },
        body: JSON.stringify({ chat_id: cid })
      };
      const response = await fetch(backURL + '/deleteChat', requestOptions);

      if (!response.ok) {
        throw new Error('Failed to fetch messages');
      }

      const mess = await response.json();
      console.log('get res : ', mess.response);
      toast.error(mess.response);
      setTimeout(() => {
        // window.location.reload();
        setQuest([]);
        setChats([]);
        setMessages([]);
        setChatHistory([]);
        fetchChatHistoryByUserId();
      }, 2000); // 2000 milliseconds = 2 seconds
    } catch (error) {
      console.error('Error fetching messages:', error);
      return [];
    }
  };

  const handleNewChat = () => {
    const newChatId = uuidv4();
    console.log('new chat : ', newChatId);
    setChatId(newChatId);
    setQuest([]);
    setChats([]);
    setMessages([]);
  }

  const [voices, setVoices] = useState([]); // To store available voices

  useEffect(() => {
    // Load available voices
    const loadVoices = () => {
      const availableVoices = window.speechSynthesis.getVoices();
      setVoices(availableVoices);
    };

    // Load voices when available
    loadVoices();

    // SpeechSynthesis API voice change event listener
    window.speechSynthesis.onvoiceschanged = loadVoices;
  }, []);

  const getFemaleVoice = () => {
    try {
      return voices.find(voice => voice.name.toLowerCase().includes('female'));
    } catch (e) {
      console.log("something else : ", e);
    }
  };

  const [copiedId, setCopiedId] = useState(null);

  const handleCopy = (text, id) => {
    try {
      navigator.clipboard.writeText(text).then(() => {
        setCopiedId(id);
        setTimeout(() => setCopiedId(null), 2000); // Reset after 2 seconds
      });
    } catch (e) {
      console.log("Error occured : ", e);
    }
  };

  const nextChat = () => {
    try {
      const next = page + 1
      setPage(next);
      fetchChatHistoryByUserId();
    } catch (e) {
      console.log("Error occured : ", e);
    }
  }

  const handlePlayPauseAudio = (text, id) => {
    try {

      if (playingId === id) {
        // If the audio is already playing, pause it
        window.speechSynthesis.cancel(); // Stop speech synthesis
        setPlayingId(null); // Reset playing state
      } else {
        // If no au
        const cleanText = text.replace(/[*#]+/g, '');
        const utterance = new SpeechSynthesisUtterance(cleanText);
        const femaleVoice = getFemaleVoice();

        if (femaleVoice) {
          utterance.voice = femaleVoice;
        }

        utterance.onend = () => {
          setPlayingId(null); // Reset when audio finishes
        };
        window.speechSynthesis.speak(utterance);
        setPlayingId(id); // Set the current message as playing
      }
    } catch (e) {
      console.log("Something else : ", e);
    }
  };


  const groupByDate = (data) => {
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);

    const grouped = {
      today: [],
      lastSevenDays: [],
      older: []
    };

    data.forEach(item => {
      const itemDate = new Date(item.latest_time);

      if (itemDate.toDateString() === today.toDateString()) {
        grouped.today.push(item);
      } else if (itemDate >= sevenDaysAgo) {
        grouped.lastSevenDays.push(item);
      } else {
        grouped.older.push(item);
      }
    });

    return grouped;
  };

  const groupedData = groupByDate(chatHistory);
  console.log('group : ', groupedData);

  console.log("sound check : ", soundOn);

  const renderChats = (title, chats) => {
    if (chats.length === 0) return null;

    return (
      <div className="chat-group">
        <h4 className="font-bold">{title}</h4>
        {chats.map((chat) => (
          <div key={chat.cid} className="chat-item relative">
            <div className="chat-message-container">
              <p
                className="chat-heading"
                onClick={() => fetchMessagesByChatOrUserId(chat.cid)}
              >
                {chat.title || "Untitled Chat"}
              </p>
              <span
                className="dots-icon"
                onClick={() => toggleDropdown(chat.cid)}
              >
                &#x2026;
              </span>
            </div>
            {dropdownVisible === chat.cid && (
              <div className="dropdown-menu">
                <ul className="py-1">
                  <li onClick={() => handleChatAction("delete", chat.cid)}>
                    Delete
                  </li>
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const formatResponse = (response) => {
    return response.replace(/([0-9]+)\.\s/g, '\n$1. ');
  };


  return (
    <div className="flex h-screen bg-gray-100">
      <ToastContainer
        position="top-left"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      {/* Sidebar */}
      <div className={`${isSidebarVisible ? 'w-64' : 'w-16'} bg-gray-800 text-white transition-all duration-300 ease-in-out`}>
        <div className="p-4 flex justify-between items-center">
          <button onClick={toggleSidebar} className="text-white">
            <FontAwesomeIcon icon={isSidebarVisible ? faTimes : faBars} />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevent event propagation
              toggleSound();
            }}
            className="ml-4 text-lg flex items-center"
          >
            <FontAwesomeIcon icon={soundOn ? faVolumeUp : faVolumeMute} />
          </button>
          {isSidebarVisible && (
            <div className="flex items-center pl-2">
              <button
                onClick={() => {
                  navigate('/home');
                }}
                className="text-sm flex items-center mr-4 relative group"
              >
                {/* Home Icon */}
                <FontAwesomeIcon icon={faHome} className="mr-2" />
                {/* Text appears on hover */}
                <span className="absolute bottom-full mb-1 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-all duration-200 text-white bg-gray-800 px-2 py-1 rounded text-xs whitespace-nowrap">
                  Home
                </span>
              </button>
            </div>
          )}

          {isSidebarVisible && (
            <button onClick={handleLogout} className="text-sm">
              <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
              Logout
            </button>
          )}
        </div>
        {isSidebarVisible && (
          <div className="p-4">
            <button
              onClick={handleNewChat}
              className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mb-4 flex items-center justify-center"
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              New Chat
            </button>
            {/* <select
              id="path"
              name="path"
              className="mb-4 w-full p-2 bg-gray-700 text-white rounded"
              onChange={handleNavigation}
            >
              <option value={subscription}>Navigate To</option>
              {subscriptions &&
                subscriptions.map((subscr) =>
                  subscr.featureValue !== subscription ? (
                    <option key={subscr.featureValue} value={subscr.featureValue}>
                      {subscr.featureName}
                    </option>
                  ) : null
                )}
            </select> */}

            <select
              id="language"
              name="language"
              className="mb-4 w-full p-2 bg-gray-700 text-white rounded"
              value={language} // Bind the value here
              onChange={handleLangChange} // Use onChange for better form handling
            >
              <option value="">Select Language</option> {/* Placeholder option */}
              {languages &&
                languages.map((lang) => (
                  <option key={lang.lang_code} value={lang.lang_code}>
                    {lang.language}
                  </option>
                ))}
            </select>
            <div className='side_auto'>

              {renderChats("Today", groupedData.today)}
              {renderChats("7 Day", groupedData.lastSevenDays)}
              {renderChats("Older", groupedData.older)}
              {page < endPage && (
                <button className="see_older" onClick={nextChat}>See Older Chat</button>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Main content */}
      <div className="flex-1 flex-col">
        <div style={STYLES.area}>
          <button style={STYLES.speak}>
            {speak || load ? 'Thinking...' : 'Type message.'}
          </button>
          {speak && (
            <button style={STYLES.stop} onClick={speakStop}>
              Stop
            </button>
          )}
        </div>

        <div className="chat-div">
          <div className="chat-box">

            {/* Render chat history from the API */}
            <p className="bot">{welcome && (
              welcome
            )}</p>
            {messages.slice().reverse().map((history, index) => (
              <div key={index} style={{ position: 'relative', marginBottom: '20px' }}>
                <p className="me">{history.question}</p>
                <p className="bot" key={index}>

                  {/* Flex container for the icons */}
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>

                    {/* Audio Play Icon (Always visible) */}
                    <span
                      className="audio-icon"
                      onClick={() => handlePlayPauseAudio(history.response, index)}
                      style={{
                        cursor: 'pointer',
                        fontSize: '18px', // Adjust icon size
                        marginLeft: '10px', // Add space between icons
                      }}
                    >
                      {playingId === index ? (
                        <FontAwesomeIcon icon={faPause} /> // Show Pause icon when playing
                      ) : (
                        <FontAwesomeIcon icon={faPlay} /> // Show Play icon when paused
                      )}
                      <span className="tooltip-text">Play/Pause</span>
                    </span>
                    {/* Copy Icon (Always visible) */}
                    <span
                      className="copy-icon"
                      onClick={() => handleCopy(history.response, index)}
                      style={{
                        cursor: 'pointer',
                        fontSize: '18px', // Adjust icon size
                      }}
                    >
                      {copiedId === index ? (
                        <FontAwesomeIcon icon={faCheck} />
                      ) : (
                        <FontAwesomeIcon icon={faCopy} />
                      )}
                      <span className="tooltip-text">Copy</span>
                    </span>
                  </div>
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {formatResponse(history.response)}
                  </ReactMarkdown>
                </p>
              </div>
            ))}

            {quest.length > 0 && (
              quest.map((chat) => {
                return <p className="me">{chat.msg}</p>;
              })
            )}
            {chats.length > 0 && (
              chats.map((chat) => {
                return <p className="bot">
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {/* {chat.msg} */}
                    {formatResponse(chat.msg)}
                  </ReactMarkdown></p>
              })
            )}

            {(load == true || speak) && !playing ? (
              <p style={{ padding: "5px", display: "flex", alignItems: "center" }}>
                <lottie-player
                  src="https://lottie.host/8891318b-7fd9-471d-a9f4-e1358fd65cd6/EQt3MHyLWk.json"
                  style={{ width: "50px", height: "50px" }}
                  loop
                  autoplay
                  speed="1.4"
                  direction="1"
                  mode="normal"
                ></lottie-player>
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="msg-box">
            <button
              className="msgbtn"
              id="mic"
              onClick={() => setIsListening(!isListening)} // Toggle mic state
            >
              <span className="tooltip-text">Tap to {isListening ? "stop" : "talk"}</span>
              {isListening ? (
                <FaMicrophone size={20} color="#007bff" /> // On-mic icon
              ) : (
                <FaMicrophoneSlash size={20} color="#ff0000" /> // Off-mic icon
              )}
            </button>
            <textarea
              className="text-area"
              value={msg}
              onChange={(e) => {
                setMsg(e.target.value);
                autoResize(e.target);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault(); // Prevents adding a new line
                  getResposnse(msg);
                }
              }}
              placeholder="Say Hello!"
            />

            <button className="msgbtn" id="send" onClick={() => getResposnse(msg)}>
              <FaPaperPlane size={20} color="#007bff" />
            </button>

          </div>
        </div>
        <ReactAudioPlayer
          src={audioSource}
          ref={audioPlayer}
          onEnded={playerEnded}
          onCanPlayThrough={playerReady}
        />
        <Canvas
          className="flex-1 flex-col"
          dpr={2}
          onCreated={(ctx) => {
            ctx.gl.physicallyCorrectLights = true;
          }}
        >
          <OrthographicCamera makeDefault zoom={1300} position={[0, 1.6, 1]} />
          <Suspense fallback={null}>
            <Environment background={false} files="/images/photo_studio_loft_hall_1k.hdr" />
          </Suspense>
          <Suspense fallback={null}>
            <Bg />
          </Suspense>
          <Suspense fallback={null}>
            <Avatar
              avatar_url="/vani1.glb"
              speak={speak}
              setSpeak={setSpeak}
              text={text}
              setAudioSource={setAudioSource}
              playing={playing}
              blendData={blendData}
              setClipActions={setClipActions}
            />
          </Suspense>
        </Canvas>
        <Loader dataInterpolation={(p) => `Loading... please wait`} />
      </div>
    </div >
  );
}

function Bg(purpose) {
  console.log('skcsb', purpose);
  const textureConstruction = useTexture('/images/background.jpg');
  const textureMaintenance = useTexture('/images/maintenance.jpg');

  let texture;
  if (purpose.purpose === 'construction') {
    texture = textureConstruction;
  } else {
    texture = textureMaintenance;
  }

  return (
    <mesh position={[0, 1.5, -4]} scale={[1.2, 1.2, 1.2]}>
      <planeGeometry args={[]} />
      <meshBasicMaterial map={texture} />
    </mesh>
  );
}
export default Chat;
