import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
// import SendOtp from "./Login/SendOtp";
import RenderPage from "./Login/Renderpage";
import HomePage from "./Login/HomePage";
import MeetingAuth from "./Login/MeetingAuth";
import Chat from "./Chat/Chat";
import Train from "./Chat/Train";
import LiveTrain from "./Chat/LiveTrain";
import TrainingRoom from "./Chat/TrainingRoom";
// import ChatRoom from "./Chat/SocketContext";
import CreateRoom from "./Chat/CreateRoom";
import PageNotFound from "./Login/PageNotFound";
// import DocAnalysis from "./Document Analysis/DocAnalysis";
import ImageAnal from "./Image Analytics/ImageAnal";
import PrivateRoute from './PrivateRoute';
// import Advance_tool from "./Advance tool/Advance_tool";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.css";

// const App = () => {

//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/" element={<RenderPage />} />
//         <Route path="chat" element={<Chat />} />
//         {/* <Route path="sendotp" element={<SendOtp />} /> */}
//         <Route path="train" element={<Train />}></Route>
//         <Route path="render" element={<RenderPage />} />
//         {/* <Route path="doc_upload" element={<DocAnalysis />} /> */}
//         <Route path="img_analysis" element={<ImageAnal />} />
//         {/* <Route path="advance_tool" element={<Advance_tool />} /> */}
//       </Routes>
//     </BrowserRouter>
//   );
// };

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <RenderPage />
            </PrivateRoute>
          }
        />

        {/* Protected routes */}
        <Route
          path="chat"
          element={
            <PrivateRoute>
              <Chat />
            </PrivateRoute>
          }
        />
        <Route
          path="train"
          element={
            <PrivateRoute>
              <Train />
            </PrivateRoute>
          }
        />
        <Route
          path="img_analysis"
          element={
            <PrivateRoute>
              <ImageAnal />
            </PrivateRoute>
          }
        />

        <Route
          path="render"
          element={
            <PrivateRoute>
              <RenderPage />
            </PrivateRoute>
          }
        />
        <Route
          path="home"
          element={
            <HomePage />
          }
        />

        <Route
          path="liveTrainingRoom"
          element={
            <LiveTrain />
          }
        />

        {/* <Route
          path="ChatRoom"
          element={
            <ChatRoom />
          }
        /> */}

        <Route
          path="livetraining/:meetingID"
          element={
            <TrainingRoom />
          }
        />

        <Route path="/meeting/:meetingID" element={<MeetingAuth />} />
        <Route path="/CreateRoom" element={<CreateRoom />} />
        {/* <Route path="/ChatRoom/:roomId" element={<ChatRoom />} /> */}
        <Route path="*" element={<PageNotFound />} />

        {/* <Route path="sendotp" element={<SendOtp />} /> */}
        {/* <Route path="doc_upload" element={<DocAnalysis />} /> */}
        {/* <Route path="advance_tool" element={<Advance_tool />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
reportWebVitals();