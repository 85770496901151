import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './Model.css'; // Modal-specific styles
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons
const backURL = process.env.REACT_APP_BACK_URL

const SignupModel = ({ closeModal, openModal }) => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [secure, setSecure] = useState({ user: 'AION@2024$safety', pass: 'Saf@tyAI@14-09-2024' });
    const [formData, setFormData] = useState({
        name: '',
        password: '',
        email: ''
        // purpose: ''
    });
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

        validateInput(name, value);
    };

    const validateInput = (name, value) => {
        if (name === "email") {
            if (value.includes("@")) {
                // Validate email format
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(value)) {
                    setErrorMessage("Please enter a valid email address.");
                } else {
                    setErrorMessage("");
                }
            } else if (/^\d+$/.test(value)) {
                // Validate phone number format
                if (value.length !== 10) {
                    setErrorMessage("Please enter a 10-digit mobile number.");
                } else {
                    setErrorMessage("");
                }
            } else {
                setErrorMessage("Enter a valid email or phone number.");
            }
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            if (formData.password && !errorMessage) {
                const toastgen = toast.loading("Please wait...");

                const response = await fetch(backURL + '/register', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...formData, ...secure }),
                });
                const data = await response.json();
                if (data.status === "success") {
                    toast.update(toastgen, { render: data.message, type: "success", isLoading: false, autoClose: 2000 });
                    // toast.success(data.message);
                    setTimeout(() => {
                        // closeModal(true);
                        openModal('login');
                        // navigate("/render");
                    }, 2000); // Navigate after delay
                } else {
                    toast.update(toastgen, { render: data.message, type: "error", isLoading: false, autoClose: 2000 });
                    // toast.error(data.message);
                }
            } else {
                alert("Please fix the errors before submitting.");
                // toast.error("Please fix the errors before submitting.");
            }
        } catch (err) {
            console.error("Error:", err);
        }
    };

    const handleCancel = () => {
        setFormData({
            name: '',
            password: '',
            email: ''
            // purpose: '',
        });
    };

    // console.log("ddads : ", options);
    return (
        <div className="modal-backdrop">
            <ToastContainer position="top-left" autoClose={4000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
            <div className="modal_login">
                <button className="close-btn" onClick={closeModal}>
                    &times;
                </button>
                <h2 className='modal-heading'>Register</h2>
                <form onSubmit={handleSubmit} className='modal-form'>
                    <div className="form-group">
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder="Name"
                            required
                            className="modal-input"
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder="Username (Email or Phone)"
                            required
                            className="modal-input"
                        />
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>
                    <div className="form-group">
                        <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
                            <input
                                type={showPassword ? "text" : "password"}
                                name="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                placeholder="Password"
                                required
                                className="modal-input"
                                style={{ paddingRight: "2.5rem" }} // Add padding to accommodate the icon
                            />
                            <span
                                onClick={togglePasswordVisibility}
                                style={{
                                    position: "absolute",
                                    right: "0.5rem",
                                    cursor: "pointer",
                                    fontSize: "1.25rem",
                                    color: "#888",
                                }}
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                    </div>

                    <button type="submit" className="auth-button">Submit</button>
                    <button type="button" className="auth-button cancel-btn" onClick={handleCancel}>Cancel</button>
                </form>
                <p className="privacy-policy">
                    By signing up, you agree to our <a href="#">Terms</a> and acknowledge our <a href="#">Privacy Policy</a>.
                </p>
            </div>
        </div>
    );
};

export default SignupModel;
