import React from "react";

const PageNotFound = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>404 - Page Not Found</h1>
            <p style={styles.message}>
                The page you're looking for doesn't exist or has been moved.
            </p>
            <button onClick={() => window.history.back()} style={styles.button}>
                Go Back
            </button>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f8f9fa',
        color: '#343a40',
        textAlign: 'center',
    },
    heading: {
        fontSize: '2.5rem',
        fontWeight: 'bold',
        marginBottom: '1rem',
    },
    message: {
        fontSize: '1.2rem',
        color: '#6c757d',
    },
};

export default PageNotFound;
