import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TrainingRoom from "../Chat/TrainingRoom"
// import io from "socket.io-client";

const backURL = process.env.REACT_APP_BACK_URL

const MeetingAuth = () => {
    const { meetingID } = useParams(); // Get the meeting ID from the URL
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [pin, setPin] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const token = localStorage.getItem('yariyan_gal' || "");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(""); // Reset error state
        setLoading(true); // Set loading state

        const requestOptions = {
            method: 'POST',
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },

            body: JSON.stringify({ name: username, meetingID: meetingID, pin: pin }),
        };
        try {
            const response = await fetch(`${backURL}/authenticate_meeting`, requestOptions);
            const result = await response.json();
            console.log('rr : ', result);
            const liveUserID = result['userID'];
            const file_ID = result['fileID'];
            const lang = result['language'];
            if (result['status'] === 'success') {
                // sessionStorage.setItem('liveUserID', liveUserID);
                // navigate('/livetraining/' + meetingID)
                navigate('/livetraining/' + meetingID, { state: { pin: pin, liveUserID: liveUserID, name: username, meetingUrl: meetingID, file_ID: file_ID, lang: lang } });
            } else {
                setError(result.msg);
            }

            // Connect to Socket.IO server
            // if (!socket.connected) socket.connect();

            // // Emit data to authenticate meeting
            // socket.emit("authenticate", {
            //     name: username,
            //     m: meetingID,
            //     p: pin
            // });

            // // Listen for server responses
            // socket.on("auth_success", () => {
            //     alert("Authentication successful!");
            //     socket.emit("join", {
            //         meetingID: meetingID,
            //         name: username
            //     });
            // });

            // socket.on("message", (message) => {
            //     console.log("Server message:", message);
            // });

            // socket.on("auth_error", (errorMsg) => {
            //     setError(errorMsg || "Invalid credentials. Try again.");
            //     console.error("Socket authentication error:", errorMsg);
            // });

        } catch (err) {
            setError("An error occurred. Please try again.");
            console.error("Error in authentication:", err);
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    return (
        <div style={{ maxWidth: "400px", margin: "50px auto", textAlign: "center" }}>
            <h2>Authenticate Meeting</h2>
            <form onSubmit={handleSubmit}>
                <div style={{ marginBottom: "15px" }}>
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        style={{ width: "100%", padding: "10px" }}
                        required
                    />
                </div>
                <div style={{ marginBottom: "15px" }}>
                    <input
                        type="text"
                        placeholder="Pin Code"
                        value={pin}
                        onChange={(e) => setPin(e.target.value)}
                        style={{ width: "100%", padding: "10px" }}
                        required
                    />
                </div>
                <button
                    type="submit"
                    disabled={loading}
                    style={{
                        width: "100%",
                        padding: "10px",
                        backgroundColor: "#007BFF",
                        color: "white",
                        border: "none",
                        cursor: "pointer",
                    }}
                >
                    {loading ? "Authenticating..." : "Authenticate"}
                </button>
                {error && <p style={{ color: "red", marginTop: "10px" }}>{error}</p>}
            </form>
        </div>
    );
};

export default MeetingAuth;
