import { io } from "socket.io-client";

let socket = null;

// Connect the socket
export const connectSocket = (url, options = {}) => {
    console.log("Attempting to connect to:", url, options);

    if (!socket) {
        socket = io(url, options);

        socket.on("connect", () => {
            console.log("Connected to server with ID:", socket.id);
        });

        socket.on("connect_error", (err) => {
            console.error("Connection error:", err.message);
        });

        socket.on("disconnect", () => {
            console.warn("Disconnected:");
        });
    }

    return socket;
};

// Get the existing socket instance
export const getSocket = () => {
    if (!socket || !socket.connected) {
        console.error("Socket is not connected. Call connectSocket first.");
        return null; // Prevent null socket issues
    }
    return socket;
};